import { DatePicker } from "antd";
import { IAlectifyDatePickerProps } from "./AlectifyDatePicker.interface";
import "./AlectifyDatePicker.scss";

const AlectifyDatePicker: React.FC<IAlectifyDatePickerProps> = (
  props: IAlectifyDatePickerProps,
) => {
  return (
    <DatePicker
      className={`alectify-datepicker ${props.className || ""}`}
      onChange={props.onChange}
      disabledDate={props.disabledDates}
      value={props.value}
      disabled={props.disabled || false}
      showTime={props.showTime}
      format={props.format}
    />
  );
};

export default AlectifyDatePicker;
