export const IR_FIELD_NAMES = {
  TITLE: "title",
  TYPE: "type",
  INCIDENT_NUMBER: "incidentNo",
  DATE: "date",
  TIME: "time",
  DESCRIPTION: "description",
  AFFECTED_SYSTEMS: "affectedSystemId",
  INCIDENT_IMPACT: "impactId",
  PRIORITY: "priority",
  ASSETS: "assets",
  TIME_OF_CALL: "callAt",
  NEXT_UPDATE: "nextUpdateAt",
  SYSTEM_STATE: "systemState",
  EMAIL_TO_CLIENT: "emailToClient",
  ACTIONS: "actions",
  MASTER_PROJECT: "masterProject",
  SUB_PROJECT: "subProject",
};

export const IR_FIELD_LABELS = {
  TITLE: "Title",
  TYPE: "Type",
  INCIDENT_NUMBER: "Incident Number",
  DATE: "Date",
  TIME: "Time",
  DESCRIPTION: "Description",
  AFFECTED_SYSTEMS: "Affected System",
  INCIDENT_IMPACT: "Incident Impact",
  PRIORITY: "Priority",
  ASSETS: "Assets",
  TIME_OF_CALL: "Time of Call",
  NEXT_UPADTE: "Next Update",
  SYSTEM_STATE: "System State",
};
