import React from "react";
import axios, { AxiosInstance, CancelTokenSource } from "axios";
import store from "redux/store";
import { message, notification } from "antd";
import * as Sentry from "@sentry/react";
import { config } from "./configs";
import { getFromLocal, removeFromLocal, saveToLocal } from "./cache";
import { AxiosResponseTypes } from "redux/interfaces";
import { actions } from "redux/components/Auth";
import { resetAllState } from "redux/rootReducer";
import { tokenService } from "./tokenService";
import OfflineIcon from "components/icons/OfflineIcon";

export class HttpService {
  private _axios: AxiosInstance;
  private cancelTokenSources: { [key: string]: CancelTokenSource } = {};

  constructor(baseURL: string = "", headers = {}) {
    this._axios = axios.create({
      baseURL,
      headers,
    });
    this.initializeInterceptors();
  }

  private initializeInterceptors() {
    // Request interceptor to attach token
    this._axios.interceptors.request.use(
      async (config) => {
        const token = getFromLocal("token");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    // Response interceptor to handle errors
    this._axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true; // Mark the request as retried
          return this.handle401Error(originalRequest);
        }
        return Promise.reject(error); // Other errors
      },
    );
  }

  // Handle 401 errors
  private async handle401Error(originalRequest: any) {
    try {
      const newToken = await tokenService.refreshToken();
      originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
      return this._axios(originalRequest); // Retry with new token
    } catch (error) {
      this.handleTokenRefreshFailure();
      return Promise.reject(error);
    }
  }

  private handleTokenRefreshFailure() {
    removeFromLocal("token");
    removeFromLocal("refresh_token");
    store.dispatch(resetAllState() as any);
    store.dispatch(actions.logout());
    notification.info({
      message: "Session expired",
      description: "Your session has expired, please log in again.",
    });
    window.location.href = "/login";
    message.destroy();
  }
  get(
    endpoint: string,
    params = {},
    requestId?: string,
    headers = {},
    responseType?: any,
  ): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };

    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;

    const config: any = {
      params,
      headers: authHeaders,
      cancelToken,
    };

    if (responseType) {
      config.responseType = responseType;
    }

    return this._axios
      .get(endpoint, config)
      .then((response) => {
        if (requestId) {
          this.removeCancelToken(requestId);
        }
        return response;
      })
      .catch(this.errorHandling);
  }

  post(endpoint: string, body: any, headers = {}, requestId?: string): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;
    return this._axios
      .post(endpoint, body, { headers: authHeaders, cancelToken })
      .then((response) => {
        if (requestId) {
          this.removeCancelToken(requestId);
        }
        return response;
      })
      .catch(this.errorHandling);
  }

  delete(endpoint: string, data?: any, headers = {}, requestId?: string): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;
    return this._axios({
      method: "delete",
      url: endpoint,
      data,
      headers: authHeaders,
      cancelToken,
    }).catch(this.errorHandling);
  }

  patch(endpoint: string, data?: any, headers = {}, requestId?: string): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;
    return this._axios({
      method: "patch",
      url: endpoint,
      data,
      headers: authHeaders,
      cancelToken,
    }).catch(this.errorHandling);
  }

  postWithResponseType(
    endpoint: string,
    body: any,
    headers = {},
    responseType: AxiosResponseTypes,
    requestId?: string,
  ): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;

    return this._axios({
      url: endpoint,
      data: body,
      method: "POST",
      responseType: responseType,
      headers: authHeaders,
      cancelToken,
    })
      .then((response) => {
        if (requestId) {
          this.removeCancelToken(requestId);
        }
        return response;
      })
      .catch(this.errorHandling);
  }

  put(endpoint: string, data: any, headers = {}, requestId?: string): any {
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${getFromLocal("token")}`,
    };
    const cancelToken = requestId
      ? this.setupCancelToken(requestId)
      : undefined;

    return this._axios({
      method: "put",
      url: endpoint,
      data,
      headers: authHeaders,
      cancelToken,
    })
      .then((response) => {
        if (requestId) {
          this.removeCancelToken(requestId);
        }
        return response;
      })
      .catch(this.errorHandling);
  }

  private request(
    method: "get" | "post" | "delete" | "patch" | "put",
    endpoint: string,
    config: any,
    requestId?: string,
  ): any {
    const token = getFromLocal("token");
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };

    if (requestId) {
      config.cancelToken = this.setupCancelToken(requestId);
    }

    return this._axios({
      url: endpoint,
      method,
      ...config,
    })
      .then((response) => {
        if (requestId) this.removeCancelToken(requestId);
        return response;
      })
      .catch(this.errorHandling);
  }

  private setupCancelToken(requestId: string) {
    this.cancelTokenSources[requestId] = axios.CancelToken.source();
    return this.cancelTokenSources[requestId].token;
  }

  private removeCancelToken(requestId: string) {
    if (this.cancelTokenSources[requestId]) {
      delete this.cancelTokenSources[requestId];
    }
  }

  errorHandling(err: any): any {
    err?.code !== "ERR_CANCELED" && Sentry.captureException(err);
    if (err?.code === "ERR_CANCELED") return;
    // Handle network error (no internet connection)
    if (err.message === "Network Error") {
      message.error({
        content:
          "No internet connection. Please check your network and try again.",
        icon: React.createElement(OfflineIcon),
      });
      return Promise.reject(err);
    }
    if (err?.response?.status === 400) {
      notification.error({
        message: err?.response?.data?.message || "Something went wrong!",
      });
    }

    if (err?.response?.status === 502 || err?.response?.status === 504) {
      notification.error({
        message: "504 Gateway Timeout: Please try again later",
      });
    }
    return Promise.reject(err);
  }

  cancelPendingRequests(requestId?: string) {
    if (requestId && this.cancelTokenSources[requestId]) {
      this.cancelTokenSources[requestId].cancel(
        "Operation canceled by the user.",
      );
      this.removeCancelToken(requestId);
    } else {
      Object.values(this.cancelTokenSources).forEach((source) => {
        source.cancel("Operation canceled by the user.");
      });
      this.cancelTokenSources = {};
    }
  }
}

const JSON_HEADERS: any = {
  "Content-Type": "application/json",
};

export const MULTIPART_HEADERS: any = {
  "Content-Type":
    "multipart/form-data; boundary=<calculated when request is sent>",
  Authorization: `Bearer ${getFromLocal("token")}`,
};

export const pmHttp = new HttpService(
  config.REACT_APP_PM_SERVICE_BASE_URL,
  JSON_HEADERS,
);
// Django Service Client
export const http = new HttpService(config.REACT_APP_BASE_URL, JSON_HEADERS);

export const llmHttp = new HttpService(
  config.REACT_APP_LLM_BASE_URL,
  JSON_HEADERS,
);

export const genAiHttp = new HttpService(
  config.REACT_APP_GEN_AI_BASE_URL,
  JSON_HEADERS,
);

export const cancelApiRequests = (requestId?: string) => {
  http.cancelPendingRequests(requestId);
};
export const cancelPMApiRequests = (requestId?: string) => {
  pmHttp.cancelPendingRequests(requestId);
};

export const cancelLlmApiRequests = (requestId?: string) => {
  llmHttp.cancelPendingRequests(requestId);
};

export const cancelGenAiApiRequests = (requestId?: string) => {
  genAiHttp.cancelPendingRequests(requestId);
};
