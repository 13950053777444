import { Spin, Row, Col, Form, Divider, Space, message } from "antd";
import {
  IIncidentReportCreateEditInitialState,
  IIncidentReportCreateEditProps,
} from "./IncidentReportCreateEdit.interface";
import "./IncidentReportCreateEdit.scss";
import IncidentBasicInfo from "./form-fragments/IncidentBasicInfo";
import IncidentTeamMembers from "./form-fragments/IncidentTeamMembers";
import IncidentImpact from "./form-fragments/IncidentImpact";
import { IMasterProject } from "redux/components/master-project";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { fetchAllUsers } from "services/users/users.service";
import { fetchTeamsList } from "services/teams/teams.service";
import { ITeams } from "services/teams/teams.interface";
import { IUser } from "redux/components/Auth";
import { ISubProject } from "redux/components/sub-project";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AssetsTable from "components/shared/assets-table";
import TextToLink from "components/shared/text-to-link";
import AlectifyButton from "components/shared/button";
import AlectifyModal from "components/shared/modal";
import IncidentAction from "./form-fragments/IncidentActions";
import {
  createIncidentReportPayload,
  initializeIncidentReportForm,
} from "./IncidentReportCreateEdit.helper";
import {
  createIncidentReport,
  getIncidentDetails,
  updateIncidentReports,
} from "services/incident-reports/incident-reports.service";
import { MODAL_NAMES } from "constants/modal.constants";
import { isEmpty } from "lodash";
import { IR_FIELD_NAMES } from "./IncidentReportCreateEdit.constants";
import IncidentAttachFiles from "./form-fragments/IncidentAttachFiles";
import { UploadFile } from "antd/lib";

const IncidentReportCreateEdit: React.FC<IIncidentReportCreateEditProps> = (
  props: IIncidentReportCreateEditProps,
) => {
  const { activeMasterProject, activeSubProject } = useSelector(
    (state: IRootState) => state.common,
  );

  const [state, setState] = useState<IIncidentReportCreateEditInitialState>({
    loading: false,
    incidentDetails: null,
    selectedMasterProject: activeMasterProject || null,
    description: "",
    selectedMembertype: "users",
    selectedTeamMembers: null,
    projectTeams: null,
    projectTeamMembers: null,
    selectedTeamId: null,
    selectedSubProject: activeSubProject || null,
    selectedRowKeys: [],
    selectedAssetPackage: null,
    selectedAssets: null,
    isRecurring: false,
    fileList: [],
  });

  const onMasterProjectSelect = async (
    value: string,
    masterProject: IMasterProject,
  ) => {
    props.FormInstance.setFieldValue(IR_FIELD_NAMES.ASSETS, null);
    props.FormInstance.setFieldValue(IR_FIELD_NAMES.SUB_PROJECT, null);
    setState((prev: IIncidentReportCreateEditInitialState) => {
      return {
        ...prev,
        selectedMasterProject: masterProject,
        selectedSubProject: null,
        selectedAssetPackage: null,
        selectedAssets: null,
        selectedTeamMembers: null,
        selectedRowKeys: [],
        selectedTeamId: null,
        selectedProcedureLibrary: null,
        projectTeams: null,
        projectTeamMembers: null,
        /*  selectedTeamMembers:
              state.selectedMembertype === "users" ? [user] : null, */
      };
    });
    getMasterProjectUsers(masterProject?.id);
    getTeamsList(masterProject?.id);
  };

  const onSubProjectSelect = (value: string, subProject: ISubProject) => {
    setState((prev) => {
      return {
        ...prev,
        selectedSubProject: subProject,
        selectedAssets: null,
        selectedAssetPackage: null,
        assetPackageTeamMembers: null,
      };
    });
  };

  const getMasterProjectUsers = async (id: string) => {
    try {
      const response = await fetchAllUsers(id || "");
      if (response.success) {
        setState((prev) => {
          return {
            ...prev,
            projectTeamMembers: response?.data,
          };
        });
      }
    } catch (ex) {}
  };

  const getTeamsList = async (id: string) => {
    const params = {
      is_active: "true",
    };
    try {
      const response = await fetchTeamsList(id || "", params as any);
      if (response.success) {
        setState((prev) => {
          return {
            ...prev,
            projectTeams: response.data,
          };
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const onSelectTeamMember = (value: string) => {
    if (value) {
      const user: any =
        state?.selectedMembertype === "teams"
          ? state.projectTeams?.find((d: ITeams) => d.id === value)
          : state.projectTeamMembers?.find((d: IUser) => d.id === value);
      const selectedUsersList = state.selectedTeamMembers || [];
      const isUserAlreadySelected = selectedUsersList.some(
        (selectedUser: IUser | ITeams) => selectedUser?.id === value,
      );
      if (
        user &&
        !isUserAlreadySelected &&
        state?.selectedMembertype === "users"
      ) {
        setState((prev: any) => ({
          ...prev,
          selectedTeamMembers: [...selectedUsersList, user],
        }));
      } else {
        setState((prev: any) => ({
          ...prev,
          selectedTeamMembers: user?.team_members || [],
          selectedTeamId: user?.id,
        }));
      }
    }
  };

  const onSelectMemberType = (value: string) => {
    setState((prev: IIncidentReportCreateEditInitialState) => ({
      ...prev,
      selectedMembertype: value,
      selectedTeamMembers: null,
    }));
  };

  const onDeselectTeamMember = (value: string) => {
    // Ensure selectedUsersList is typed correctly
    const selectedUsersList: (IUser | ITeams)[] =
      state.selectedTeamMembers || [];

    // Filter out the deselected member from the selected users list
    const updatedSelectedUsersList = selectedUsersList.filter(
      (selectedUser) => selectedUser.id !== value,
    );

    // Update the state with the new list of selected members
    setState((prev: any) => ({
      ...prev,
      selectedTeamMembers: updatedSelectedUsersList,
    }));
  };

  const getCheckboxProps = (record: any) => ({
    disabled: record.type === "DisabledType", // Adjust this logic as needed
    name: record.name, // Example property
  });

  const equipmentModalConfig = {
    width: 1200,
    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
    title: "Select Asset",
    children: (
      <AssetsTable
        selectRowOption
        subProjectId={state.selectedSubProject?.id}
        extra={
          <Space>
            <div>Selected Asset(s): {state.selectedRowKeys.length}</div>
            <TextToLink
              text="Clear All"
              underline
              className="assets-selection-clear-all-text"
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  selectedAssets: null,
                  selectedAssetPackage: null,
                  selectedRowKeys: [],
                }));
              }}
            />
          </Space>
        }
        showSearch
        scroll={{ y: "400px" }}
        selectedRowKeys={state.selectedRowKeys}
        rowSelection={{
          selectedRowKeys: state.selectedRowKeys,
          onChange: (newSelectedRowKeys: number[], selectedRows) => {
            setState((prev: IIncidentReportCreateEditInitialState) => ({
              ...prev,
              selectedRowKeys: selectedRows,
            }));
            props.FormInstance.setFieldValue("assets", selectedRows);
          },
        }}
        getCheckboxProps={getCheckboxProps}
        onRowSelectionAction={(record: any[], key) => {
          setState((prev: IIncidentReportCreateEditInitialState) => ({
            ...prev,
            selectedRowKeys: key as any,
          }));
          props.FormInstance.setFieldValue("assets", key);

          setState((prev: IIncidentReportCreateEditInitialState) => {
            // props.FormInstance.setFieldsValue({ assets: record });
            return {
              ...prev,
              selectedAssetPackage: record.filter(
                (d) => d.type === "PackageRoom",
              ),
              selectedAssets: record.filter((d) => d.type === "Tag"),
            };
          });
        }}
      />
    ),
    footer: (
      <Space>
        <AlectifyButton
          name="close"
          text="Cancel"
          type="default"
          className="alectify-default-button"
          onClick={() => {
            ModalServiceInstance.close(AlectifyModal, {
              name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
            });
          }}
        />
        <AlectifyButton
          type="primary"
          className="alectify-primary-button"
          name="Submit"
          text="Confirm"
          onClick={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
            })
          }
        />
      </Space>
    ),
    onCancel: () =>
      ModalServiceInstance.close(AlectifyModal, {
        name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
      }),
  };

  const equipmentModalHandler = () =>
    ModalServiceInstance.open(AlectifyModal, equipmentModalConfig);
  const onSubmit = async (values: FormDataEntryValue) => {
    setState((prev: IIncidentReportCreateEditInitialState) => ({
      ...prev,
      loading: true,
    }));
    try {
      const payload = createIncidentReportPayload(state, values, props);
      let formData = new FormData();
      if (!isEmpty(state.fileList)) {
        state.fileList.map((fileList: any) => {
          formData.append("files", fileList as Blob);
          return [];
        });
      }
      for (let key in payload) {
        if (key === "areaIds") {
          payload[key].forEach((ap: any, index: any) => {
            ap && formData.append(`areaIds[${index}]`, ap);
          });
        } else if (key === "assetIds") {
          payload[key].forEach((ap: any, index: any) => {
            ap && formData.append(`assetIds[${index}]`, ap);
          });
        } else if (key === "teamMembers") {
          payload[key].forEach((as: any, index: any) => {
            as && formData.append(`teamMembers[${index}]`, as);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }
      if (props.editing && props.incidentReportId) {
        const response = await updateIncidentReports(
          props.incidentReportId,
          formData,
        );
        if (response.status) {
          props.callback && props.callback();
          message.success("Incident Report has been updated successfully.");
          props.FormInstance.resetFields();
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
          });
        }
      } else {
        const response = await createIncidentReport(formData);
        if (response.status) {
          props.callback && props.callback();
          message.success("Incident Report has been created successfully.");
          props.FormInstance.resetFields();
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
          });
        }
      }

      setState((prev: IIncidentReportCreateEditInitialState) => ({
        ...prev,
        loading: false,
      }));
    } catch (ex) {
      setState((prev: IIncidentReportCreateEditInitialState) => ({
        ...prev,
        loading: false,
      }));
      console.log(ex);
    }
  };

  const getDetails = async (id: string) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await getIncidentDetails(id);
      if (response?.status) {
        getMasterProjectUsers(response.data?.project.id);
        getTeamsList(response.data?.project?.id);
        const responseTeam = Array.isArray(
          response.data?.team?.projectTeamMembers,
        )
          ? response?.data?.team?.projectTeamMembers.flatMap(
              (member: any) => member.user,
            )
          : [];
        const responseUsers =
          response?.data?.teamMembers?.map((member: any) => member.user) ?? [];
        /*  const allAssetsKeys = [...response.data?.assets, ...response.data?.areas];
        if (!isEmpty(allAssetsKeys)) {
          setState((prev: IIncidentReportCreateEditInitialState) => ({
            ...prev,
            selectedRowKeys: allAssetsKeys.map(
              (d: any) => d.asset?.id || d.area?.id,
            ) as any,
          }));
        } */
        setState((prev) => ({
          ...prev,
          loading: false,
          description: response?.data?.description || "",
          incidentDetails: response.data,
          selectedTeamMembers: isEmpty(response?.data?.teamMembers)
            ? (responseTeam as IUser[] | ITeams[])
            : (responseUsers as IUser[] | ITeams[]),
          selectedMembertype: isEmpty(response?.data?.teamMembers)
            ? "teams"
            : "users",
          selectedAssets:
            response.data?.assets &&
            (response.data?.assets.map((d: any) => d.asset) as any),
          selectedAssetPackage:
            response.data?.areas &&
            (response.data?.areas.map((d: any) => d.area) as any),
        }));
      }
      initializeIncidentReportForm(props.FormInstance, response?.data);
    } catch (ex) {
      setState((prev) => ({ ...prev, loading: true }));
      console.log(ex);
    }
  };

  const setFileList = (fileList: UploadFile[]) => {
    setState((prev: IIncidentReportCreateEditInitialState) => ({
      ...prev,
      fileList,
    }));
  };

  useEffect(() => {
    if (state.selectedMasterProject) {
      getTeamsList(state.selectedMasterProject?.id);
      getMasterProjectUsers(state.selectedMasterProject.id);
    }
    if (props.editing && props.incidentReportId) {
      getDetails(props.incidentReportId);
    }
    if (!isEmpty(activeMasterProject) && !props.editing) {
      props.FormInstance.setFieldValue(
        IR_FIELD_NAMES.MASTER_PROJECT,
        activeMasterProject.id,
      );
      setState((prev: IIncidentReportCreateEditInitialState) => ({
        ...prev,
        selectedMasterProject: activeMasterProject,
      }));
    }
  }, []);

  useEffect(() => {
    ModalServiceInstance.updateModalProps(
      DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
      equipmentModalConfig,
    );
  }, [state.selectedRowKeys]);

  return (
    <Spin spinning={state.loading}>
      <Row
        justify="center"
        align="middle"
        // style={{ minHeight: "100vh", display: "flex" }} // Ensures full height and flexbox alignment
      >
        <Col span={24} className="alectify-wo-create-edit-container">
          <Form
            form={props.FormInstance}
            onFinish={onSubmit}
            onFinishFailed={(error) => console.log(error)}
            layout="vertical"
            initialValues={{
              emailToClient: false, // Add the default value for your checkbox
            }}
          >
            <IncidentBasicInfo
              selectedMasterProject={state.selectedMasterProject}
              selectedSubProject={state.selectedSubProject}
              onSelectMasterProject={onMasterProjectSelect}
              onSelectSubProject={onSubProjectSelect}
              editing={props.editing}
              FormInstance={props.FormInstance}
              incidentDetail={state.incidentDetails}
            />
            <Divider />
            <IncidentTeamMembers
              FormInstance={props.FormInstance}
              incidentDetails={state.incidentDetails}
              masterProjectId={state.selectedMasterProject?.id || ""}
              selectedMembertype={state.selectedMembertype || ""}
              onSelectTeamMember={onSelectTeamMember}
              onSelectMemberType={onSelectMemberType}
              selectedTeamMembers={state.selectedTeamMembers || []}
              editing={props.editing}
              projectTeams={state.projectTeams}
              projectTeamMembers={state.projectTeamMembers}
              onSelect={onSelectTeamMember}
              onDeselectTeamMember={onDeselectTeamMember}
            />
            <Divider />
            <IncidentImpact
              masterProjectId={state.selectedMasterProject?.id || ""}
              selectedMasterProject={state.selectedMasterProject}
              FormInstance={props.FormInstance}
              selectedSubProject={state.selectedSubProject}
              selectedAssetPackage={state.selectedAssetPackage}
              selectedAssets={state.selectedAssets}
              selectedRowKeys={state.selectedRowKeys}
              editing={props.editing}
              equipmentModalHandler={equipmentModalHandler}
              incidentDetail={state.incidentDetails}
            />
            <Divider />
            <IncidentAction
              FormInstance={props.FormInstance}
              incidentDetail={state.incidentDetails}
              editing={props.editing}
            />
            <Divider />
            <IncidentAttachFiles
              editing={props.editing}
              incidentDocuments={state.incidentDetails?.documents}
              setFileList={setFileList}
            />
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default IncidentReportCreateEdit;
