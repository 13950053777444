import { ITableFilterInitialState } from "./TableFilter.interface";

export const tableFilterInitialState: ITableFilterInitialState = {
  tableFilters: {
    createdAt: null,
    dueDate: null,
    status: null,
    taskCategory: null,
    orderTypeTab: "all",
    statusShifterButtons: ["PENDING", "WAITING FOR REVIEW"],
  },
};
