import React from "react";
import { Avatar, Space, Tooltip } from "antd";
import { get, isEmpty, truncate } from "lodash";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import AlectifyText from "static/texts.json";
import { IRootState } from "redux/rootReducer";
import {
  displayDateTime,
  enumToTile,
  getFullUserName,
  isExternalUser,
} from "utils/helpers";
import { MaintenanceCategoriesEnum, TaskPriorityEnum } from "enums";
import AlectifyChip from "components/shared/chips/AlectifyChip";
import { CalendarDateIcon, UserIcon } from "components/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { setActiveMasterProject } from "redux/components/common/sources";
import { IMasterProject } from "redux/components/master-project";
import TextToLink from "components/shared/text-to-link";

import AlectifyBulletIcon from "components/icons/BulletIcon";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import SwitchSubProjectDrawer from "pages/sub-project/swtich-sub-project-drawer";
import AlectifyButton from "components/shared/button";
import { CloseOutlined } from "@ant-design/icons";

import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import ViewImage from "components/shared/view-image/ViewImage";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import AlectifyModal from "components/shared/modal";
import "../WorkOrder.styles.scss";

interface IMyWorkOrderColumnsProps {
  showPmType?: boolean;
  showOverDueTag?: boolean;
  myWorkOrder?: boolean;
  isTaskType?: boolean;
  onCell?: any;
}

const MyWorkOrderColumns = (props?: IMyWorkOrderColumnsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(({ auth }: IRootState) => auth);

  const onMasterProjectClick = (masterProject: IMasterProject) => {
    if (!isExternalUser(user)) {
      dispatch(setActiveMasterProject(masterProject));
      navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject.id}`);
    }
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
    });
  };

  // const onSubProjectClick = (
  //   masterProject: IMasterProject,
  //   subProject: ISubProject,
  // ) => {
  //   if (!isExternalUser(user)) {
  //     dispatch(setActiveSubProject(subProject));
  //     dispatch(setActiveMasterProject(masterProject));
  //     navigate(
  //       `${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject?.id}${ROUTES.SUB_PROJECT}/${subProject.id}`,
  //     );
  //     DrawerServiceInstance.close(AlectifyDrawer, {
  //       name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
  //     });
  //   }
  // };

  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.CREATED,
          key: "created",
          dataIndex: "created_at",
          visible: !props?.isTaskType,
          ellipsis: true,
          width: 130,
          onCell: props?.onCell,
          render: (target: any, record: any) => {
            return (
              <div className="d-flex justify-content-space-between">
                <div className="status-column">
                  {record?.priority === TaskPriorityEnum.CRITICAL ? (
                    <div className="critical-text">{AlectifyText.CRITICAL}</div>
                  ) : null}
                </div>
                <Space
                  direction="vertical"
                  size={15}
                  className={
                    record?.priority === TaskPriorityEnum.CRITICAL
                      ? "ml-30"
                      : ""
                  }
                >
                  <div className="creator-container">
                    <CalendarDateIcon />
                    <span className="creator-name-date">
                      {`${displayDateTime(
                        record?.created_at || record?.createdAt,
                        true,
                        false,
                      )}`}
                    </span>
                  </div>

                  <div className="creator-container">
                    {isEmpty(record?.createdBy?.image_url) ? (
                      <UserIcon />
                    ) : (
                      <Avatar
                        src={
                          record?.createdBy?.image_url &&
                          record?.createdBy?.image_url
                        }
                        size={25}
                        className="cursor-pointer"
                        onClick={() => {
                          ModalServiceInstance.open(AlectifyModal, {
                            name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                            footer: null,
                            title: "Preview Image",
                            onCancel: () => {
                              ModalServiceInstance.close(AlectifyModal, {
                                name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                              });
                            },
                            children: (
                              <ViewImage
                                imageUrl={
                                  record?.createdBy?.image_url ||
                                  NO_IMAGE_AVATAR
                                }
                                isDownloadable={
                                  !isEmpty(record?.createdBy?.image_url)
                                }
                              />
                            ),
                          });
                        }}
                      />
                    )}
                    <span className="creator-name-date">
                      {`${
                        record?.created_by && !isEmpty(record?.created_by)
                          ? getFullUserName(record?.created_by[0])
                          : record?.createdBy && !isEmpty(record?.createdBy)
                          ? getFullUserName(record?.createdBy)
                          : "-"
                      }`}
                    </span>
                    {record?.taskCategory && (
                      <Tooltip
                        title={`${enumToTile(record.taskCategory || "")}`}
                      >
                        <span>
                          <AlectifyChip
                            text={
                              record?.taskCategory ===
                              MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                                ? AlectifyText.CM
                                : record?.taskCategory ===
                                  MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
                                ? AlectifyText.PM
                                : AlectifyText.TASK
                            }
                            textColor={
                              record?.taskCategory ===
                              MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                                ? "red"
                                : "0954f1"
                            }
                            backgroundColor={
                              record?.taskCategory ===
                              MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                                ? "#fde8e8"
                                : "e8f1fd"
                            }
                            borderColor={
                              record?.taskCategory ===
                              MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                                ? "#ff0000"
                                : "e8f1fd"
                            }
                          />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </Space>
              </div>
            );
          },
        },

        {
          title: AlectifyText.CREATED,
          key: "created",
          dataIndex: "created_at",
          visible: props?.isTaskType,
          ellipsis: true,
          width: 120,
          onCell: props?.onCell,
          render: (target: any, record: any) => {
            return (
              <>
                <div className="status-column">
                  {record?.priority === TaskPriorityEnum.CRITICAL ? (
                    <div className="critical-text">{AlectifyText.CRITICAL}</div>
                  ) : null}
                </div>
                <Space direction="vertical" size={15} className="ml-30">
                  <div className="creator-container">
                    <CalendarDateIcon />
                    <span className="creator-name-date">
                      {`${displayDateTime(
                        record?.created_at || record?.createdAt,
                        true,
                        false,
                      )}`}
                    </span>
                  </div>

                  <div className="creator-container">
                    {isEmpty(record?.createdBy?.image_url) ? (
                      <UserIcon />
                    ) : (
                      <Avatar
                        src={
                          record?.createdBy?.image_url &&
                          record?.createdBy?.image_url
                        }
                        size="small"
                      />
                    )}
                    <span className="creator-name-date">
                      {`${
                        record?.created_by && !isEmpty(record?.created_by)
                          ? getFullUserName(record?.created_by[0])
                          : record?.createdBy && !isEmpty(record?.createdBy)
                          ? getFullUserName(record?.createdBy)
                          : "-"
                      }`}
                    </span>
                  </div>
                </Space>
              </>
            );
          },
        },
        {
          key: "master-project",
          dataIndex: "project",
          title: AlectifyText.SITE_AND_ASSET_CATEGORY,
          width: 100,
          ellipsis: {
            showTitle: false,
          },
          visible: true,
          onCell: props?.onCell,
          render: (target: any, record: any) => {
            const masterProject = record?.projects
              ? get(record, "projects[0]", target)
              : record?.project;
            const subProject = record?.sub_projects
              ? get(record, "sub_projects[0]", target)
              : record?.subProject;
            return (
              <Space direction="vertical" size={15}>
                {masterProject?.name ? (
                  <Tooltip placement="topLeft" title={masterProject?.name}>
                    <Space direction="horizontal" size={8}>
                      <AlectifyBulletIcon />
                      {(!isExternalUser(user) && (
                        <TextToLink
                          className="text-to-link-options"
                          text={truncate(masterProject?.name, {
                            length: 20,
                            omission: "...",
                          })}
                          underline={!isExternalUser(user)}
                          onClick={(e) => {
                            e.stopPropagation();
                            DrawerServiceInstance.open(AlectifyDrawer, {
                              width: 420,
                              name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                              title: masterProject?.name,
                              closable: true,
                              closeIcon: <CloseOutlined />,
                              onClose: () =>
                                DrawerServiceInstance.close(AlectifyDrawer, {
                                  name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                                }),
                              children: (
                                <SwitchSubProjectDrawer
                                  masterProjectId={masterProject.id}
                                  navigate={navigate}
                                />
                              ),
                              customFooter: (
                                <AlectifyButton
                                  text={AlectifyText.VISIT_SITE}
                                  className="ant-btn-primary alectify-btn-block "
                                  block
                                  onClick={() =>
                                    onMasterProjectClick(masterProject)
                                  }
                                />
                              ),
                            });
                          }}
                        />
                      )) ||
                        truncate(masterProject?.name, {
                          length: 20,
                          omission: "...",
                        })}
                    </Space>
                  </Tooltip>
                ) : (
                  "-"
                )}
                <Tooltip placement="topLeft" title={subProject?.name}>
                  <Space direction="horizontal" size={8}>
                    <AlectifyBulletIcon />
                    {truncate(subProject?.name, {
                      length: 20,
                      omission: "...",
                    })}
                  </Space>
                </Tooltip>
              </Space>
            );
          },
        },
      ].filter((column) => column.visible),
    [],
  );
};

export default MyWorkOrderColumns;
