import {
  Breadcrumb,
  Col,
  Divider,
  Dropdown,
  message,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import {
  IIncidentDetailsInitialState,
  IIncidentDetailsProps,
} from "./IncidentDetails.interface";
import { useNavigate, useParams } from "react-router-dom";
import BackArrowIcon from "components/icons/BackArrowIcon";
import {
  displayDateTime,
  enumToTile,
  isExternalUser,
  truncateText,
} from "utils/helpers";
import { ROUTES } from "routes/Routes.constants";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteIncidentDocument,
  deleteIncidentReports,
  getIncidentDetails,
  updateIncidentReportStatus,
} from "services/incident-reports/incident-reports.service";
import IncidentTypeTag from "components/shared/tags/IncidentTypeTag";
import CheckIcon from "components/icons/CheckIcon";
import CrossIcon from "components/icons/CrossIcon";
import "./IncidentDetails.scss";
import { MenuProps } from "antd/lib";
import AlectifyButton from "components/shared/button";
import { DownOutlined, ExportOutlined } from "@ant-design/icons";
import { isEmpty, truncate } from "lodash";
import { IUser } from "redux/components/Auth";
import { ITeams } from "services/teams/teams.interface";
import AlectifyTable from "components/shared/table";
import AvatarGroup from "components/shared/avatar-group";
import IncidentComments from "./IncidentComments";
import DangerTag from "components/shared/tags/DangerTag";
import AlectifyText from "static/texts.json";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import IncidentReportsDocuments from "components/incident-report/IncidentReportsDocuments";
import { MESSAGES } from "constants/messages";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { useForm } from "antd/lib/form/Form";
import IncidentReportCreateEdit from "../incident-report-creation/IncidentReportCreateEdit";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import ExternalTag from "components/shared/tags/ExternalTag";
import TextToLink from "components/shared/text-to-link";
import { AssetPackageTag, AssetTag } from "components/shared/tags";
import {
  AlectifyDetailCard,
  AlectifyDetailCardText,
} from "components/shared/detail-card";
import { StatusEnum } from "enums";

const IncidentReportDetails: React.FC<IIncidentDetailsProps> = (
  props: IIncidentDetailsProps,
) => {
  const [state, setState] = useState<IIncidentDetailsInitialState>({
    loading: false,
    incidentDetails: null,
    selectedTeamMembers: null,
    selectedAssetPackage: null,
    selectedAssets: null,
  });
  const [EditForm] = useForm();
  const { user } = useSelector((state: IRootState) => state.auth);
  const navigate = useNavigate();
  const params = useParams();

  const prepareRoutes = (type: string): string => {
    let url: string = "";
    if (type === "home")
      url = (!isExternalUser(user) && navigate(`${ROUTES.PROJECT}`)) || "";
    return url;
  };

  const getDetails = async (id: string) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await getIncidentDetails(id);
      if (response?.status) {
        const responseTeam = Array.isArray(
          response.data?.team?.projectTeamMembers,
        )
          ? response?.data?.team?.projectTeamMembers.flatMap(
              (member: any) => member.user,
            )
          : [];
        const responseUsers =
          response?.data?.teamMembers?.map((member: any) => member.user) ?? [];
        setState((prev) => ({
          ...prev,
          loading: false,
          incidentDetails: response.data,
          selectedTeamMembers: isEmpty(response?.data?.teamMembers)
            ? (responseTeam as IUser[] | ITeams[])
            : (responseUsers as IUser[] | ITeams[]),
          selectedAssets:
            response.data?.assets &&
            (response.data?.assets.map((d: any) => d.asset) as any),
          selectedAssetPackage:
            response.data?.areas &&
            (response.data?.areas.map((d: any) => d.area) as any),
        }));
      }
    } catch (ex) {
      setState((prev) => ({ ...prev, loading: true }));
      console.log(ex);
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      className:
        state.incidentDetails?.status === StatusEnum.CLOSED
          ? "not-allowed"
          : "pointer",
      onClick: () => {
        openCreationModal(true, state.incidentDetails?.id || params.id || "");
      },
      disabled: state.incidentDetails?.status === StatusEnum.CLOSED,
    },
    {
      key: "2",
      label: "Delete",
      className:
        state.incidentDetails?.status === StatusEnum.CLOSED
          ? "not-allowed"
          : "pointer",
      onClick: () => {
        openDeleteConfirmationModal(
          state.incidentDetails?.id || params.id || "",
        );
      },
      disabled: state.incidentDetails?.status === StatusEnum.CLOSED,
    },
    {
      key: "3",
      label:
        state.incidentDetails?.status === StatusEnum.CLOSED
          ? AlectifyText.REOPEN
          : AlectifyText.CLOSED,
      onClick: () => {
        showStatusConfirmationModal(
          state.incidentDetails?.id || params.id || "",
          state.incidentDetails?.status === StatusEnum.CLOSED
            ? "OPEN"
            : "CLOSED",
        );
      },
    },
  ];

  const getAssetsLength = () => {
    const assetCount = state.incidentDetails?.assets.length || 0;
    const areaCount = state.incidentDetails?.areas.length || 0;
    const total = assetCount + areaCount || 0;
    const displayText =
      total > 1
        ? total
        : (state.incidentDetails?.assets[0] as any)?.asset?.name ||
          (state.incidentDetails?.areas[0] as any)?.area?.name ||
          "-";
    return displayText;
  };

  const handleDocumentDelete = async (id: string) => {
    try {
      if (!isEmpty(id)) {
        const response = await deleteIncidentDocument(id);
        if (response.status) {
          message.success(MESSAGES.INCIDENT_REPORTS.FILE_DELETED);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openAttachmentsDrawer = (e: any) => {
    e.stopPropagation();
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 860,
      closeIcon: true,
      title: AlectifyText.ATTACHMENTS,
      name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
      onClose: () => {
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
        });
      },
      children: (
        <IncidentReportsDocuments
          details={state.incidentDetails?.documents || []}
          onDeleteDocuments={handleDocumentDelete as any}
        />
      ),
    });
  };

  const openCreationModal = (editing = true, id: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
      className: MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
      title: "Create Incident Report",
      width: 800,
      style: { top: 30 },
      okText: "Submit",
      onOk: EditForm.submit,
      destroyOnClose: true,
      onCancel: () => {
        EditForm.resetFields();
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.INCIDENT_REPORT_CREATION_MODAL,
        });
      },
      children: (
        <IncidentReportCreateEdit
          FormInstance={EditForm}
          editing={editing}
          incidentReportId={id}
          callback={() => getDetails(params.id || "")}
        />
      ),
    });
  };

  const handleDeleteIncident = async (id: string) => {
    try {
      const response = await deleteIncidentReports(id);
      if (response) {
        message.success(MESSAGES.INCIDENT_REPORTS.DELETE);
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
        });
        navigate(ROUTES.INCIDENT_REPORTS);
      } else {
        message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteConfirmationModal = (id: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
      title: "Delete Report",
      onOk: () => {
        handleDeleteIncident(id);
      },
      footer: null,
      closable: false,
      children: (
        <ConfirmationModal
          onConfirm={() => handleDeleteIncident(id)}
          message="Are you sure you want to remove this report?"
          onCancel={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.INCIDENT_REMOVE_MODAL,
            })
          }
        />
      ),
    });
  };

  const handleIncidentStatusChange = async (id: string, newStatus: string) => {
    try {
      if (!isEmpty(id)) {
        const payload = {
          status: newStatus,
        };
        const response = await updateIncidentReportStatus(id, payload);
        if (response.status) {
          message.success(MESSAGES.INCIDENT_REPORTS.STATUS_UPDATED);
        } else {
          message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
        }

        getDetails(id);
      }
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const showStatusConfirmationModal = (id: string, newStatus: string) => {
    const statusText = newStatus === "CLOSED" ? "Close" : "Reopen";
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
      title: `${statusText} Report`,
      footer: null,
      closable: false,
      children: (
        <ConfirmationModal
          onConfirm={() => handleIncidentStatusChange(id, newStatus)}
          message={`Are you sure you want to ${statusText.toLowerCase()} this report?`}
          onCancel={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.INCIDENT_STATUS_MODAL,
            })
          }
        />
      ),
    });
  };

  const openAssetModal = (e: any) => {
    e.stopPropagation();
    if (!isEmpty(state.incidentDetails)) {
      const hasMultipleAssets = state.incidentDetails?.assets.length > 1;
      const hasMultipleAreas = state.incidentDetails?.areas.length > 1;

      if (hasMultipleAssets || hasMultipleAreas) {
        // Open the modal with the list of assets and areas
        ModalServiceInstance.open(AlectifyModal, {
          name: MODAL_NAMES.ASSET_NAME_MODAL,
          title: `Asset list of (${state.incidentDetails?.title})`,
          width: 800,
          footer: null,
          children: (
            <AlectifyTable
              onDataFetch={() => {}}
              dataSource={[
                ...state.incidentDetails?.assets.map((asset: any) => {
                  return { ...asset.asset, type: "Tag" };
                }),
                ...state.incidentDetails?.areas.map((ar: any) => {
                  return {
                    ...ar.area,
                    type: "PackageRoom",
                  };
                }),
              ]}
              total={
                [
                  ...state.incidentDetails?.assets,
                  ...state.incidentDetails?.areas,
                ].length
              }
              showPagination={false}
              columns={[
                {
                  dataIndex: "name",
                  title: "Name",
                  width: 180,
                  render: (value, assetRecord) => {
                    const goToAssetDetails = () => {
                      let url: string;
                      if (assetRecord.type === "PackageRoom") {
                        url = `${ROUTES.SUB_PROJECT}/${state.incidentDetails?.subProject.id}/timeline/asset-package/${assetRecord.id}?page=timeline`;
                      } else {
                        url = `${ROUTES.SUB_PROJECT}/${state.incidentDetails?.subProject.id}/timeline/asset/${assetRecord.id}?page=timeline`;
                      }
                      window.open(url, "_blank"); // Open in a new tab
                    };
                    return (
                      <TextToLink
                        text={
                          <Space>
                            <Tooltip title={value || "NA"}>
                              <span>{truncateText(value, 29)}</span>
                            </Tooltip>
                            <ExportOutlined />
                          </Space>
                        }
                        onClick={goToAssetDetails}
                      />
                    );
                  },
                },

                {
                  title: AlectifyText.ASSET_TYPE,
                  key: "asset_type",
                  width: 140,
                  render: (value, assetRecord) => {
                    return (
                      <>
                        {assetRecord.type === "Tag" ? (
                          <AssetTag />
                        ) : assetRecord.type === "PackageRoom" ? (
                          <AssetPackageTag />
                        ) : (
                          "-"
                        )}
                      </>
                    );
                  },
                },
                {
                  dataIndex: "location",
                  title: AlectifyText.LOCATION,
                  width: 160,
                  ellipsis: true,
                  render: (location, assetRecord) => {
                    return (
                      <Tooltip title={location || "NA"}>
                        {truncateText(location || "-", 30)}
                      </Tooltip>
                    );
                  },
                },
                {
                  dataIndex: "description",
                  title: AlectifyText.DESCRIPTION,
                  width: 200,
                  ellipsis: true,
                  render: (description, assetRecord) => {
                    return (
                      <Tooltip title={description || "NA"}>
                        {truncateText(description || "-", 37)}
                      </Tooltip>
                    );
                  },
                },
              ]}
            />
          ),
          onCancel: () =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.ASSET_NAME_MODAL,
            }),
        });
      } else {
        // Redirect directly to asset/area detail page
        const singleAsset: any =
          state.incidentDetails?.assets.length === 1
            ? state.incidentDetails?.assets[0]
            : null;
        const singleArea: any =
          state.incidentDetails?.areas.length === 1
            ? state.incidentDetails?.areas[0]
            : null;

        if (singleAsset) {
          const url = `${ROUTES.SUB_PROJECT}/${state.incidentDetails?.subProject.id}/timeline/asset/${singleAsset.asset.id}?page=timeline`;
          window.open(url, "_blank");
        } else if (singleArea) {
          const url = `${ROUTES.SUB_PROJECT}/${state.incidentDetails?.subProject.id}/timeline/asset-package/${singleArea.area.id}?page=timeline`;
          window.open(url, "_blank");
        }
      }
    }
  };

  const openUserModal = (e: any) => {
    e.stopPropagation();
    const onClose = () => {
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.INCIDENT_TEAM_MEMBERS_MODAL,
      });
    };
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_TEAM_MEMBERS_MODAL,
      title: "Team Members",
      width: 800,
      footer: null,
      onCancel: onClose,
      children: (
        <AlectifyTable
          showPagination={false}
          bordered={false}
          size="small"
          columns={[
            {
              title: "Full Name",
              dataIndex: "name",
              render: (value, record: IUser) => (
                <Space>
                  <AvatarGroup users={[record]} showImage />
                  <span>
                    {record.first_name} {record.last_name}
                  </span>
                  {isExternalUser(record) ? "(External)" : ""}
                </Space>
              ),
            },
            {
              title: "Email",
              dataIndex: "email",
            },
            {
              title: "Phone",
              dataIndex: "contactNumber",
              render: (value) => <span>{value || "-"}</span>,
            },
            {
              title: "User Type",
              dataIndex: "user_type",
              render: (value, record: IUser) => (
                <span>{isExternalUser(record) ? <ExternalTag /> : "-"}</span>
              ),
            },
          ]}
          dataSource={state.selectedTeamMembers || []}
          total={0}
          onDataFetch={() => {}}
          className="mt-5"
        />
      ),
    });
  };

  const openBasicInfoModal = (e: any) => {
    const onClose = () => {
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.INCIDENT_BASIC_INFO_MODAL,
      });
    };
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.INCIDENT_BASIC_INFO_MODAL,
      title:
        truncate(state.incidentDetails?.title, {
          length: 50,
          omission: "...",
        }) || "-",
      onCancel: onClose,
      footer: null,
      children: (
        <div>
          <AlectifyDetailCard>
            <>
              <AlectifyDetailCardText
                label={AlectifyText.INCIDENT_NUMBER}
                value={state.incidentDetails?.incidentNo || "-"}
                className="m-0"
              />
              <AlectifyDetailCardText
                label={AlectifyText.INCIDENT_DATE}
                value={displayDateTime(
                  state.incidentDetails?.incidentDate as any,
                  true,
                )}
              />
              <AlectifyDetailCardText
                label={AlectifyText.TYPE}
                value={enumToTile(state.incidentDetails?.type || "") || "-"}
              />
              <AlectifyDetailCardText
                label={AlectifyText.INCIDENT_IMPACT}
                value={state.incidentDetails?.impact?.name || "-"}
              />
              <AlectifyDetailCardText
                label={AlectifyText.AFFECTED_SYSTEM}
                value={state.incidentDetails?.affectedSystem?.name || "-"}
              />
            </>
          </AlectifyDetailCard>
        </div>
      ),
    });
  };

  useEffect(() => {
    if (params.id) {
      getDetails(params.id);
    }
  }, [params.id]);

  return (
    <Spin spinning={state.loading}>
      <Row
        justify={"start"}
        align={"top"}
        className="incident-details-container"
        gutter={[12, 12]}
      >
        <Col span={24}>
          <div id="idToExclude" className="d-flex align-items-center">
            <div
              className="cursor-pointer mt-2 mr-5"
              onClick={() => navigate(-1)}
            ></div>

            <Breadcrumb
              className="m-0"
              items={[
                {
                  title: "Home",
                  href: `#`,
                  onClick: () => navigate(prepareRoutes("home")),
                },
                {
                  title: `${state.incidentDetails?.project?.name || "-"}`,
                  href: "#",
                  onClick: () => navigate(prepareRoutes("projects")),
                },
                {
                  title: `Incident Report`,
                  href: "#",
                  onClick: () => navigate(prepareRoutes("projects")),
                },
              ]}
            />
          </div>
        </Col>
        <Col span={24}>
          <Space direction="horizontal">
            <BackArrowIcon
              height="12"
              width="20"
              fill="#3e403f"
              className="cursor-pointer"
              onClick={() => navigate(ROUTES.INCIDENT_REPORTS)}
            />
            <Typography.Title level={5} className="m-0">
              {truncate(state.incidentDetails?.title, {
                length: 100,
                omission: "...",
              })}
            </Typography.Title>
            {state.incidentDetails?.priority === "CRITICAL" ? (
              <DangerTag text={AlectifyText.CRITICAL} />
            ) : (
              ""
            )}
          </Space>
        </Col>
        {/* <Divider className="m-0" /> */}
        <Col span={12}>
          <Space>
            <IncidentTypeTag
              text={enumToTile(state.incidentDetails?.type || "")}
            />
            <IncidentTypeTag
              text={"Email to client"}
              icon={
                state.incidentDetails?.emailToClient ? (
                  <CheckIcon fill="#02c705" />
                ) : (
                  <CrossIcon fill="#e6020a" />
                )
              }
            />
            <Space>
              <span>Incident Impact:</span>
              <span>{state.incidentDetails?.impact?.name || "-"}</span>
            </Space>
            <Space className="ml-30">
              <span>Affect Systems:</span>
              <span>{state.incidentDetails?.affectedSystem?.name || "-"}</span>
            </Space>
          </Space>
        </Col>
        <Col span={12} className="text-align-right">
          <Dropdown
            className="alectify-work-order-task-edit-btn"
            menu={{ items }}
            trigger={["click"]}
            // disabled={!items?.length}
          >
            <AlectifyButton
              name="pme-detail-action-btn"
              text={"Open"}
              type="primary"
              rightIcon={<DownOutlined />}
            />
          </Dropdown>
        </Col>
        <Divider className="m-0" />
        <Col span={24}>
          <Row justify={"start"} align={"middle"} gutter={[50, 16]}>
            <Col span={6}>
              <div
                className="incident-statistic-card cursor-pointer"
                onClick={openBasicInfoModal}
              >
                <span>Incident #</span>
                <Typography.Title level={3} className="mt-5">
                  {state.incidentDetails?.incidentNo || "-"}
                </Typography.Title>
                <Divider className="m-0" />
                <span className="incident-statistic-card-helper-text">
                  {displayDateTime(
                    state.incidentDetails?.incidentDate as any,
                    true,
                  )}
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                className="incident-statistic-card cursor-pointer"
                onClick={openUserModal}
              >
                <span>Team Members</span>
                <Typography.Title level={3} className="mt-5">
                  {state.selectedTeamMembers?.length || "0"}
                </Typography.Title>
                <Divider className="m-0" />
                <span className="incident-statistic-card-helper-text">
                  <AvatarGroup
                    users={(state.selectedTeamMembers as any) || []}
                    showImage
                    size={"small"}
                  />
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div
                className="incident-statistic-card cursor-pointer"
                onClick={openAssetModal}
              >
                <span>Impacted Assets</span>
                <Divider className="mt-10 mb-10" />
                <Typography.Title level={3} className="mt-5">
                  {getAssetsLength()}
                </Typography.Title>
              </div>
            </Col>
            <Col span={6}>
              <div
                className="incident-statistic-card cursor-pointer"
                onClick={openAttachmentsDrawer}
              >
                <span>Attachments</span>
                <Divider className="mt-10 mb-10" />
                <Typography.Title level={3} className="mt-5">
                  {state.incidentDetails?.documents.length || 0}
                </Typography.Title>
              </div>
            </Col>
          </Row>
        </Col>

        {/* <Col span={12}>
          <Col span={24}>
            <div className="incident-detail-description-container">
              <Row justify={"start"} align={"top"}>
                <Col span={24}>
                  <Row justify={"start"} align={"middle"} gutter={[18, 54]}>
                    <Col span={12}>
                      <Space>
                        <span>Incident #:</span>
                        <span className="incident-detail-info-value-chips">
                          {state.incidentDetails?.incidentNo || ""}
                        </span>
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space>
                        <span>Incident Date Time:</span>
                        {(state.incidentDetails?.incidentDate && (
                          <span className="incident-detail-info-value-chips">
                            {displayDateTime(
                              (state.incidentDetails?.incidentDate as any) ||
                                "",
                              true,
                            )}
                          </span>
                        )) ||
                          "-"}
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space>
                        <span>Affected System(s):</span>
                        {(state.incidentDetails?.affectedSystem?.name && (
                          <span className="incident-detail-info-value-chips">
                            {state.incidentDetails?.affectedSystem?.name}
                          </span>
                        )) ||
                          "-"}
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space>
                        <span>Incident Impact:</span>
                        {(state.incidentDetails?.impact?.name && (
                          <span className="incident-detail-info-value-chips">
                            {state.incidentDetails?.impact?.name || ""}
                          </span>
                        )) ||
                          "-"}
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space>
                        <span>Impacted Asset(s):</span>
                        <span className="incident-detail-info-value-chips">{`${getAssetsLength()} Assets`}</span>
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space>
                        <span>Attachment(s):</span>
                        <div
                          className="attachment-count-container"
                          onClick={openAttachmentsDrawer}
                        >
                          <AttachmentIcon />
                          <span>
                            {state?.incidentDetails?.documents?.length || 0}
                          </span>
                        </div>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Col>
        <Col span={12}>
          <Row justify={"start"} align={"top"}>
            <Col span={24} className="mb-10">
              <div className="incident-detail-description-container">
                <AlectifyTable
                  showPagination={false}
                  bordered={false}
                  scroll={{ y: "130px " }}
                  size="small"
                  columns={[
                    {
                      title: "Full Name",
                      dataIndex: "name",
                      render: (value, record: IUser) => (
                        <Space>
                          <AvatarGroup users={[record]} showImage />
                          <span>
                            {record.first_name} {record.last_name}
                          </span>
                          {isExternalUser(record) ? "(External)" : ""}
                        </Space>
                      ),
                    },
                    {
                      title: "Email",
                      dataIndex: "email",
                    },
                    {
                      title: "Phone",
                      dataIndex: "contactNumber",
                      render: (value) => <span>{value || "-"}</span>,
                    },
                    {
                      title: "User Type",
                      dataIndex: "user_type",
                      render: (value, record: IUser) => (
                        <span>
                          {isExternalUser(record) ? <ExternalTag /> : "-"}
                        </span>
                      ),
                    },
                  ]}
                  dataSource={state.selectedTeamMembers || []}
                  total={0}
                  onDataFetch={() => {}}
                  className="mt-5"
                />
              </div>
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Divider className="mb-10" />
      <IncidentComments incidentId={state.incidentDetails?.id} />
    </Spin>
  );
};

export default IncidentReportDetails;
