import { Space, Tag } from "antd";
import { ReactElement, ReactNode } from "react";
import AlectifyText from "static/texts.json";

const IncidentTypeTag: React.FC<{
  text?: string;
  icon?: ReactElement | ReactNode;
}> = ({ text, icon }) => {
  return (
    <Tag color={"#F3F3F3"} className="steps-tag incident-tag round-tag">
      <Space size={2}>
        {`${text || "-"}`} {icon && icon}
      </Space>
    </Tag>
  );
};

export default IncidentTypeTag;
