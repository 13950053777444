import { SourceActionType } from "redux/interfaces";
import { Dispatch } from "react";
import { actions } from "..";

export const resetTableFilterState: SourceActionType = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.resetTableFilterState());
    } catch (ex) {
      console.log(ex);
    }
  };
};

export const setTableFilters: SourceActionType = (payload: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setTableFilters(payload));
  };
};
