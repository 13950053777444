//libs
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlectifyText from "static/texts.json";

//absolute
import AlectifyTable from "components/shared/table";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { IRootState } from "redux/rootReducer";
import { ISparePartsTableProps } from "./SparePartsTable.interface";
import useSparePartsColumns from "./effects/useSparePartsColumns";
import { getSpareParts } from "redux/components/spare-parts/sources";
import { IProjectSparePart } from "redux/components/spare-parts";
import { useForm } from "antd/lib/form/Form";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import DrawSparePartForm from "../draw-spare-part";
import RefillSparePartForm from "../refill-spare-part";
import CreateEditSparePart from "../create-edit-spare-part";
import DrawHistory from "../draw-history";
import { CloseOutlined } from "@ant-design/icons";
import AlectifyButton from "../button";
import {
  deleteOneSparePart,
  deleteSparePartOrder,
  downloadSparePartsAsCSV,
} from "services/spare-parts/spare-parts.service";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import OrderSparePartForm from "../refill-spare-part/OrderSparePartForm";
import { Form, message } from "antd";
import { deleteRecentlyAddedSpareParts } from "redux/components/spare-parts/sources";
import ConfirmationModal from "../confirmation/Confirmation";
import { WarningIcon } from "components/icons";
import { MESSAGES } from "constants/messages";

const SparePartsTable: React.FC<ISparePartsTableProps> = (
  props: ISparePartsTableProps,
) => {
  const dispatch = useDispatch();
  const [OrderFormInstance] = Form.useForm();
  const [DrawFormInstance] = useForm();
  const [RefillFormInstance] = useForm();
  const [EditFormInstance] = useForm();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { spareParts, common } = useSelector((state: IRootState) => state);

  useEffect(() => {
    fetchSpareParts();
  }, [common.activeMasterProject?.id]);

  const fetchSpareParts = (options?: IPagination) => {
    const params: any = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      ...options,
    };
    if (props.refill) {
      params.pendingOrdersOnly = true;
    }
    if (props.specificMasterProjectId) {
      params["projectId"] = props.specificMasterProjectId;
    } else if (
      common.activeMasterProject?.id &&
      common.activeMasterProject?.id !== "all"
    ) {
      params["projectId"] = common.activeMasterProject?.id;
    }
    dispatch(getSpareParts(params, props.isGlobal));
  };

  const openDrawDrawer = (sparePart: IProjectSparePart) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 1200,
      title: AlectifyText.DRAW_ITEM,
      name: DRAWER_CONSTANTS.DRAWER_DRAW_SPARE_PART,
      showFooter: true,
      closeIcon: <CloseOutlined />,
      closable: true,
      onClose: () => {
        DrawFormInstance.resetFields();
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.DRAWER_DRAW_SPARE_PART,
        });
      },
      onSubmit: DrawFormInstance.submit,
      children: (
        <DrawSparePartForm
          sparePart={sparePart}
          FormInstance={DrawFormInstance}
          fetchSpareParts={fetchSpareParts}
        />
      ),
    });
  };

  const openRefillDrawer = (sparePart: IProjectSparePart) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: DRAWER_CONSTANTS.DRAWER_REFILL_SPARE_PART,
      title: `${AlectifyText.RECEIVE_ORDER} for Part: ${sparePart.sparePart.partNumber}`,
      width: 500,
      okText: "Receive Order",
      destroyOnClose: true,
      onOk: () => RefillFormInstance.submit(),
      onCancel: () => {
        RefillFormInstance.resetFields();
        ModalServiceInstance.close(AlectifyModal, {
          name: DRAWER_CONSTANTS.DRAWER_REFILL_SPARE_PART,
        });
      },
      closeIcon: <CloseOutlined />,
      closable: true,
      children: (
        <RefillSparePartForm
          sparePart={sparePart}
          FormInstance={RefillFormInstance}
          fetchSpareParts={fetchSpareParts}
        />
      ),
    });
    // DrawerServiceInstance.open(AlectifyDrawer, {
    //   width: 1200,
    //   title: `${AlectifyText.REFILL_PART} ${sparePart.sparePart.partNumber}`,
    //   name: DRAWER_CONSTANTS.DRAWER_REFILL_SPARE_PART,
    //   showFooter: false,
    //   onClose: () => {
    //     RefillFormInstance.resetFields();
    //     DrawerServiceInstance.close(AlectifyDrawer, {
    //       name: DRAWER_CONSTANTS.DRAWER_REFILL_SPARE_PART,
    //     });
    //   },
    //   onSubmit: RefillFormInstance.submit,
    //   closeIcon: <CloseOutlined />,
    //   closable: true,
    //   children: (
    //     <RefillSparePartForm
    //       sparePart={sparePart}
    //       FormInstance={RefillFormInstance}
    //       fetchSpareParts={fetchSpareParts}
    //     />
    //   ),
    // });
  };

  const onDeleteSparePart = async (sparePart: IProjectSparePart) => {
    const onCancel = () =>
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
      });
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
      title: AlectifyText.CONFIRMATION,
      footer: false,
      onCancel,
      children: (
        <ConfirmationModal
          icon={WarningIcon}
          message={MESSAGES.SPARE_PART_MESSAGES.REMOVER_WARNING.replace(
            "{partNumber}",
            sparePart.sparePart.partNumber,
          )}
          onConfirm={async () => {
            onCancel();
            await deleteOneSparePart(sparePart.id);
            fetchSpareParts();
            dispatch(deleteRecentlyAddedSpareParts([sparePart.id]));
            message.success("Deleted");
          }}
          onCancel={onCancel}
        />
      ),
    });
  };

  const onDeleteSparePartOrder = async (sparePart: IProjectSparePart) => {
    const onCancel = () =>
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
      });
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
      title: AlectifyText.CONFIRMATION,
      footer: false,
      onCancel,
      children: (
        <ConfirmationModal
          icon={WarningIcon}
          message={MESSAGES.SPARE_PART_MESSAGES.DELETE_ORDER}
          onConfirm={async () => {
            onCancel();
            await deleteSparePartOrder(sparePart.manageOrders[0].id);
            fetchSpareParts();
            message.success("Deleted");
          }}
          onCancel={onCancel}
        />
      ),
    });
  };

  const openSparePartForm = (sparePart?: IProjectSparePart) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 800,
      title: sparePart
        ? AlectifyText.UPDATE_INVENTORY
        : AlectifyText.ADD_NEW_INVENTORY,
      name: DRAWER_CONSTANTS.ADD_NEW_SPARE_PART_DRAWER,
      showFooter: true,
      submitText: sparePart ? "Update" : "Receive",
      closeIcon: <CloseOutlined />,
      closable: true,
      children: (
        <CreateEditSparePart
          disableQuantityFields
          formInstance={EditFormInstance}
          existingSparePart={sparePart}
          fetchSpareParts={fetchSpareParts}
        />
      ),
      onSubmit: EditFormInstance.submit,
      onClose: () =>
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.ADD_NEW_SPARE_PART_DRAWER,
        }),
    });
  };

  const openDrawHistory = (sparePart: IProjectSparePart) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 1400,
      title: `${sparePart?.sparePart.partNumber} History`,
      name: DRAWER_CONSTANTS.DRAW_HISTORY_DRAWER,
      showFooter: false,
      children: <DrawHistory sparePart={sparePart} />,
      closeIcon: <CloseOutlined />,
      closable: true,
      onClose: () =>
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.DRAW_HISTORY_DRAWER,
        }),
    });
  };

  const openOrderModal = (sparePart?: IProjectSparePart) => {
    OrderFormInstance.resetFields();
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.SPARE_PARTS_CREATE_ORDER,
      title: sparePart
        ? `Update Order: ${sparePart?.manageOrders?.[0]?.poNumber}`
        : "Add Order",
      width: 650,
      okText: sparePart ? "Update Order" : "Add Order",
      destroyOnClose: true,
      onOk: () => OrderFormInstance.submit(),
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.SPARE_PARTS_CREATE_ORDER,
        });
      },
      children: (
        <OrderSparePartForm
          form={OrderFormInstance}
          sparePart={sparePart}
          fetchSpareParts={fetchSpareParts}
        />
      ),
    });
  };

  const columsParams = () => {
    if (props.refill) {
      return {
        openRefillDrawer,
        openOrderModal,
        openDrawHistory,
        onDeleteSparePartOrder,
      };
    } else if (props.isGlobal) {
      return { openDrawHistory };
    }
    return {
      openDrawDrawer,
      openDrawHistory,
      openSparePartForm,
      onDeleteSparePart,
    };
  };

  const columns = useSparePartsColumns({
    ...columsParams(),
    isGlobal: props.isGlobal,
    onRowSelect: props.onRowSelect,
    showAction: props.showAction,
    selectRowOption: props.selectRowOption,
    pendingOrdersTab: props.refill,
    disabledOrderedParts: props.disabledOrderedParts,
    showSelectbutton: props?.showSelectbutton,
    fetchSpareParts,
  });

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true);
      const params = {
        projectId: common.activeMasterProject?.id,
      };
      const response = await downloadSparePartsAsCSV(params);

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "alectify_spare_parts.csv");

      document.body.appendChild(link);

      link.click();
      link?.parentNode?.removeChild(link);

      setIsDownloading(false);
    } catch (error) {
      console.error("Error downloading CSV:", error);
      setIsDownloading(false);
    }
  };

  const displayRowClass = (record: any) => {
    return record.manageOrders?.length && props.disabledOrderedParts
      ? "disable-row"
      : "";
  };

  return (
    <AlectifyTable
      showSearch
      noIcontainsSearch
      columns={columns}
      scroll={props.scroll}
      dataSource={spareParts?.data}
      loading={spareParts.fetching}
      total={spareParts?.meta?.totalItems}
      onDataFetch={fetchSpareParts}
      // columnFilterAsString
      customSearchKey="search"
      displayRowClass={displayRowClass}
      size="small"
      extraButton={
        props.showDownloadCSV ? (
          <>
            <AlectifyButton
              text="Download CSV"
              type="primary"
              onClick={handleDownloadCsv}
              loading={isDownloading}
            />
          </>
        ) : props.allowOrderCreation ? (
          <AlectifyButton
            text="Add Order"
            type="primary"
            onClick={() => openOrderModal()}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

SparePartsTable.defaultProps = {
  isGlobal: false,
  showAction: true,
};

export default memo(SparePartsTable);
