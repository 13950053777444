import { Space, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import AssetsIcon from "components/icons/AssetsIcon";
import DetailIcon from "components/icons/DetailIcon";
import GroupCheckpointsTable from "components/shared/group-checkpoint-table/GroupCheckpointsTable";
import { useParams } from "react-router-dom";
import AlectifyText from "static/texts.json";
import DataRoundInsights from "./DataRoundInsights/DataRoundInsights";
import { useEffect, useState } from "react";
import AnalyticsIcon from "components/icons/AnalyticsIcon";
import DataRoundAnalytics from "./DataRoundAnalytics/DataRoundAnalytics";
import { PROJECT_THEME_COLOR_SECONDARY } from "components/shared/phone-number/constants";
import DataRoundReports from "components/shared/data-rounds-reports";
import DeadlinesIcon from "components/icons/DeadlinesIcon";

interface TabItem {
  key: string;
  label: React.ReactNode;
  children: React.ReactNode;
  visible: boolean; // New property
}

const DataRoundProjectTabs: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState<string>("insights");

  const { projectId } = useParams();

  const [FormInstance] = useForm();
  const items: TabItem[] = [
    {
      key: "insights",
      label: (
        <Space>
          <DetailIcon />
          <span>{AlectifyText.INSIGHTS}</span>
        </Space>
      ),
      children: <DataRoundInsights projectId={projectId || ""} />,
      visible: true,
    },
    {
      key: "checkpoints",
      label: (
        <Space>
          <AssetsIcon />
          <span>{AlectifyText.CHECKPOINT_LIST}</span>
        </Space>
      ),
      children: (
        <GroupCheckpointsTable
          projectId={projectId || ""}
          form={FormInstance}
          displayActionButton={false}
          onClose={() => {}}
        />
      ),
      visible: true,
    },
    {
      key: "reports",
      label: (
        <Space>
          <DeadlinesIcon />
          <span>{AlectifyText.REPORTS}</span>
        </Space>
      ),
      children: <DataRoundReports />,
      visible: true,
    },
    {
      key: "analytics",
      label: (
        <Space>
          <AnalyticsIcon
            fill={
              activeTab === "analytics" ? PROJECT_THEME_COLOR_SECONDARY : null
            }
          />
          <span>{AlectifyText.ANALYTICS}</span>
        </Space>
      ),
      children: <DataRoundAnalytics />,
      visible: true,
    },
  ];
  useEffect(() => {}, []);
  return (
    <div className="sub-project-tabs">
      <Tabs
        items={items.filter((item) => item.visible)}
        defaultActiveKey={activeTab}
        onChange={setActiveTab}
      />
    </div>
  );
};

export default DataRoundProjectTabs;
