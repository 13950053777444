import { memo } from "react";

interface CheckIconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

const CheckIcon: React.FC<CheckIconProps> = ({
  width = 25,
  height = 23,
  fill = "#61BC45",
  className = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 23"
      fill="none"
      className={className}
      style={{
        verticalAlign: "middle",
      }}
      preserveAspectRatio="xMinYMin meet"
    >
      <path
        d="M24.9481 2.97126C24.8613 2.66102 24.5936 2.37802 24.2848 2.25899C24.1382 2.2021 23.9245 2.17342 23.6433 2.17198C22.5246 2.16673 21.4402 2.35077 20.391 2.72508C17.8638 3.6257 15.7321 5.39301 13.9772 7.36396C12.9097 8.5624 11.9294 9.86984 11.0361 11.2853C11.0346 11.2872 11.0332 11.2891 11.0308 11.291C11.0206 11.2987 11.0062 11.2973 10.9984 11.2872C10.1875 10.2637 9.41042 9.29237 8.66706 8.37406C8.38149 8.02174 8.16248 7.78989 8.01005 7.67947C7.63475 7.40746 7.22327 7.28078 6.77514 7.29943C6.42637 7.31424 6.11957 7.39838 5.85571 7.55087C4.95557 8.0705 4.59571 9.12601 4.99561 10.0692C5.0699 10.2446 5.21461 10.4655 5.42928 10.7322C6.8007 12.4326 8.15862 14.1292 9.50256 15.8224C9.75677 16.1427 9.95696 16.3544 10.1026 16.4582C10.6897 16.8745 11.4432 16.9577 12.105 16.6647C12.6053 16.4429 12.9656 16.0557 13.1861 15.5035C13.7239 14.155 14.3674 12.8413 15.1161 11.5626C16.104 9.87558 17.2622 8.28849 18.6684 6.96241C19.7017 5.98769 20.9332 5.15925 22.2694 4.7381C22.7542 4.58512 23.2641 4.48904 23.7981 4.45032C24.1445 4.42546 24.4358 4.35136 24.6688 4.10708C24.9771 3.78393 25.0702 3.40532 24.9481 2.97126Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(CheckIcon);
