import {
  Col,
  Divider,
  Form,
  message,
  notification,
  Row,
  Space,
  Spin,
} from "antd";
import {
  IWorkOrderCreateEditProps,
  IWorkOrderInitialState,
} from "./WorkOrderCreateEdit.interface";
import BasicInformation from "./form-fragments/BasicInformation";
import "./WorkOrderCreateEdit.styles.scss";
import TeamMemberSelection from "./form-fragments/TeamMembersSelection";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { IMasterProject } from "redux/components/master-project";
import { ITeams } from "services/teams/teams.interface";
import { IUser } from "redux/components/Auth";
import { fetchTeamsList } from "services/teams/teams.service";
import { fetchAllUsers } from "services/users/users.service";
import WorkOrderSchedule from "./form-fragments/WorkOrderSchedule";
import {
  createWorkOrderPayload,
  initializeWorkOrderForm,
} from "./WorkOrderCreateEdit.helpers";
import { ISubProject } from "redux/components/sub-project";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AssetsTable from "components/shared/assets-table";
import AlectifyButton from "components/shared/button";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import {
  WORK_ORDER_EDITING_TYPES,
  WORK_ORDER_FORM_FIELD_TYPES,
} from "./WorkOrderCreateEdit.constants";
import {
  createMasterPmApprovers,
  createMasterPmAssignees,
  createPmApprovers,
  createPmAssignees,
  createPrevMaintenances,
  fetchExternalPMDetails,
  fetchPMDetailsMaster,
  removeMasterPmApprovers,
  removeMasterPmAssignees,
  removePmApprovers,
  removePmAssignees,
  updatePrevMaintenances,
} from "services/pm-external/pm-external.service";
import PmExternalIcon from "components/icons/PmExternalIcon";
import TextToLink from "components/shared/text-to-link";
import { ITask } from "redux/components/tasks";
import { isEmpty } from "lodash";
import { ROUTES } from "routes/Routes.constants";
import { useNavigate } from "react-router-dom";
import { MODAL_NAMES } from "constants/modal.constants";
import { removeNullUndefined } from "utils/helpers";
import WorkOrderProcedureSelection from "./form-fragments/ProcedureSelection";
import { IProcedure } from "services/procedures/procedures.interface";
import { IPmAssgineeCreationPayload } from "services/pm-external/pm-external.interface";
import { PM_TYPES } from "redux/components/pm-external";
import { MESSAGES } from "constants/messages";
import TasksIcon from "components/icons/TasksIcon";
import { MaintenanceCategoriesEnum } from "enums";

const WorkOrderCreateEdit: React.FC<IWorkOrderCreateEditProps> = (
  props: IWorkOrderCreateEditProps,
) => {
  const { activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );
  const { tableFilters } = useSelector(({ tableFilter }) => tableFilter);

  const navigate = useNavigate();

  const [state, setState] = useState<IWorkOrderInitialState>({
    loading: false,
    selectedMasterProject: activeMasterProject || null,
    selectedMembertype: "users",
    selectedTeamMembers: null,
    projectTeams: null,
    projectTeamMembers: null,
    selectedTeamId: null,
    selectedSubProject: null,
    selectedRowKeys: [],
    selectedAssetPackage: null,
    selectedAssets: null,
    isRecurring: false,
    taskDetails: null,
    selectedProcedureLibrary: null,
    procedureLibraryId: null,
    taskCategory: null,
    fileList: [],
  });

  const onMasterProjectSelect = async (
    value: string,
    masterProject: IMasterProject,
  ) => {
    props.FormInstance.setFieldValue(WORK_ORDER_FORM_FIELD_TYPES.ASSETS, null);
    props.FormInstance.setFieldValue(
      WORK_ORDER_FORM_FIELD_TYPES.SUB_PROJECT,
      null,
    );
    //@Temp: removing default assignees and approvers
    props.FormInstance.setFieldValue(
      WORK_ORDER_FORM_FIELD_TYPES.ASSIGNEES,
      undefined,
    );
    props.FormInstance.setFieldValue(
      WORK_ORDER_FORM_FIELD_TYPES.APPROVERS,
      undefined,
    );
    setState((prev: IWorkOrderInitialState) => {
      return {
        ...prev,
        selectedMasterProject: masterProject,
        selectedSubProject: null,
        selectedAssetPackage: null,
        selectedAssets: null,
        selectedTeamMembers: null,
        selectedRowKeys: [],
        selectedTeamId: null,
        selectedProcedureLibrary: null,
        projectTeams: null,
        projectTeamMembers: null,
        /*  selectedTeamMembers:
          state.selectedMembertype === "users" ? [user] : null, */
      };
    });
    getMasterProjectUsers(masterProject?.id);
    getTeamsList(masterProject?.id);
  };

  const onSubProjectSelect = (value: string, subProject: ISubProject) => {
    props.FormInstance.setFieldValue("assets", null);
    setState((prev) => {
      return {
        ...prev,
        selectedSubProject: subProject,
        selectedAssets: null,
        selectedAssetPackage: null,
        assetPackageTeamMembers: null,
      };
    });
  };

  const getMasterProjectUsers = async (id: string) => {
    try {
      const response = await fetchAllUsers(id || "");
      if (response.success) {
        setState((prev) => {
          return {
            ...prev,
            projectTeamMembers: response?.data,
          };
        });
      }
    } catch (ex) {}
  };

  const onRecurringChange = (value: boolean) => {
    setState((prev: IWorkOrderInitialState) => ({
      ...prev,
      isRecurring: value,
    }));
  };

  const handleImageUpload = (fileList: any[]) => {
    setState((prev) => {
      return {
        ...prev,
        fileList,
      };
    });
  };

  const getTeamsList = async (id: string) => {
    const params = {
      is_active: "true",
    };
    try {
      const response = await fetchTeamsList(id || "", params as any);
      if (response.success) {
        setState((prev) => {
          return {
            ...prev,
            projectTeams: response.data,
          };
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const onSelectTaskCategory = (value: string) => {
    setState((prev: IWorkOrderInitialState) => ({
      ...prev,
      taskCategory: value,
    }));
  };

  const getCheckboxProps = (record: any) => ({
    disabled: record.type === "DisabledType", // Adjust this logic as needed
    name: record.name, // Example property
  });

  const equipmentModalConfig = {
    width: 1200,
    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
    title: "Select Asset",
    children: (
      <AssetsTable
        selectRowOption
        subProjectId={state.selectedSubProject?.id}
        extra={
          <Space>
            <div>Selected Asset(s): {state.selectedRowKeys.length}</div>
            <TextToLink
              text="Clear All"
              underline
              className="assets-selection-clear-all-text"
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  selectedAssets: null,
                  selectedAssetPackage: null,
                  selectedRowKeys: [],
                }));
              }}
            />
          </Space>
        }
        showSearch
        scroll={{ y: "400px" }}
        selectedRowKeys={state.selectedRowKeys}
        rowSelection={{
          selectedRowKeys: state.selectedRowKeys,
          onChange: (newSelectedRowKeys: number[], selectedRows) => {
            setState((prev: IWorkOrderInitialState) => ({
              ...prev,
              selectedRowKeys: selectedRows,
            }));
            props.FormInstance.setFieldValue("assets", selectedRows);
          },
        }}
        getCheckboxProps={getCheckboxProps}
        onRowSelectionAction={(record: any[], key) => {
          setState((prev: IWorkOrderInitialState) => ({
            ...prev,
            selectedRowKeys: key as any,
          }));
          props.FormInstance.setFieldValue("assets", key);

          setState((prev: IWorkOrderInitialState) => {
            // props.FormInstance.setFieldsValue({ assets: record });
            return {
              ...prev,
              selectedAssetPackage: record.filter(
                (d) => d.type === "PackageRoom",
              ),
              selectedAssets: record.filter((d) => d.type === "Tag"),
            };
          });
        }}
      />
    ),
    footer: (
      <Space>
        <AlectifyButton
          name="close"
          text="Cancel"
          type="default"
          className="alectify-default-button"
          onClick={() => {
            ModalServiceInstance.close(AlectifyModal, {
              name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
            });
          }}
        />
        <AlectifyButton
          type="primary"
          className="alectify-primary-button"
          name="Submit"
          text="Confirm"
          onClick={() =>
            ModalServiceInstance.close(AlectifyModal, {
              name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
            })
          }
        />
      </Space>
    ),
    onCancel: () =>
      ModalServiceInstance.close(AlectifyModal, {
        name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
      }),
  };

  const equipmentModalHandler = () =>
    ModalServiceInstance.open(AlectifyModal, equipmentModalConfig);

  const onSelectMemberType = (value: string) => {
    props.FormInstance.setFieldValue("assignees", undefined);
    props.FormInstance.setFieldValue("approvers", undefined);
    setState((prev: IWorkOrderInitialState) => ({
      ...prev,
      selectedMembertype: value,
      selectedTeamMembers: null,
    }));
  };

  const onSelectTeamMember = (value: string) => {
    if (value) {
      const user: any =
        state?.selectedMembertype === "teams"
          ? state.projectTeams?.find((d: ITeams) => d.id === value)
          : state.projectTeamMembers?.find((d: IUser) => d.id === value);
      const selectedUsersList = state.selectedTeamMembers || [];
      const isUserAlreadySelected = selectedUsersList.some(
        (selectedUser: IUser | ITeams) => selectedUser?.id === value,
      );
      if (
        user &&
        !isUserAlreadySelected &&
        state?.selectedMembertype === "users"
      ) {
        setState((prev: any) => ({
          ...prev,
          selectedTeamMembers: [...selectedUsersList, user],
        }));
      } else {
        setState((prev: any) => ({
          ...prev,
          selectedTeamMembers: user?.team_members || [],
          selectedTeamId: user?.id,
        }));
        props.FormInstance.setFieldValue("assignees", undefined);
        props.FormInstance.setFieldValue("approvers", undefined);
      }
    }
  };

  const onDeselectTeamMember = (value: string) => {
    // Ensure selectedUsersList is typed correctly
    const selectedUsersList: (IUser | ITeams)[] =
      state.selectedTeamMembers || [];

    // Filter out the deselected member from the selected users list
    const updatedSelectedUsersList = selectedUsersList.filter(
      (selectedUser) => selectedUser.id !== value,
    );

    // Update the state with the new list of selected members
    setState((prev: any) => ({
      ...prev,
      selectedTeamMembers: updatedSelectedUsersList,
    }));

    // If the deselected member is part of assignees or approvers, update the form
    const assignees: string[] =
      props.FormInstance.getFieldValue("assignees") || [];
    const approvers: string[] =
      props.FormInstance.getFieldValue("approvers") || [];

    // Update assignees if the deselected member was in the list
    if (assignees.includes(value)) {
      props.FormInstance.setFieldValue(
        "assignees",
        assignees.filter((id) => id !== value),
      );
    }

    // Optionally, update approvers if necessary (similar to assignees logic)
    if (approvers.includes(value)) {
      props.FormInstance.setFieldValue(
        "approvers",
        approvers.filter((id) => id !== value),
      );
    }
  };

  const onSelectProcedure = (procedure: IProcedure | null) => {
    setState((prev) => {
      return {
        ...prev,
        selectedProcedureLibrary: procedure,
        procedureLibraryId: isEmpty(procedure)
          ? null
          : state.procedureLibraryId,
      };
    });
  };

  const createAssignee = async (user: IUser, type = "assignees") => {
    setState((prev) => {
      return { ...prev, loading: true };
    });
    try {
      const payload: IPmAssgineeCreationPayload = {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        userId: user.id,
        imageUrl: user.image_url || "",
      };
      if (props.editType === WORK_ORDER_EDITING_TYPES.ALL_EVENTS) {
        if (type === "assignees") {
          await createMasterPmAssignees(
            state.taskDetails?.id || props.taskId,
            payload,
          );
        } else {
          await createMasterPmApprovers(
            state.taskDetails?.id || props.taskId,
            payload,
          );
        }
      } else {
        if (type === "assignees") {
          await createPmAssignees(
            state.taskDetails?.id || props.taskId,
            payload,
          );
        } else {
          await createPmApprovers(
            state.taskDetails?.id || props.taskId,
            payload,
          );
        }
      }
      setState((prev) => {
        return { ...prev, loading: false };
      });
      message.success(
        `${type === "assignees" ? "Assignee" : "Approvers"} has been added`,
      );
    } catch (ex) {
      setState((prev) => {
        return { ...prev, loading: false };
      });
      message.error("couldn't add assignee");
      console.log(ex);
    }
  };

  const removeAssignee = async (userId: string, type = "assignees") => {
    setState((prev) => {
      return { ...prev, loading: true };
    });

    try {
      if (props.editType === WORK_ORDER_EDITING_TYPES.ALL_EVENTS) {
        // sends master pm id for future tasks
        if (type === "assignees") {
          await removeMasterPmAssignees(state.taskDetails?.id || "", userId);
        } else {
          await removeMasterPmApprovers(state.taskDetails?.id || "", userId);
        }
      } else {
        // parent pm id for current task
        if (type === "assignees") {
          await removePmAssignees(props.taskId || "", userId);
        } else {
          await removePmApprovers(props.taskId || "", userId);
        }
      }
      setState((prev) => {
        return { ...prev, loading: false };
      });
      message.success(
        `${type === "assignees" ? "Assignee" : "Approver"} has been removed.`,
      );
    } catch (ex) {
      setState((prev) => {
        return { ...prev, loading: false };
      });
      message.error("couldn't remove assignee");
      console.log(ex);
    }
  };

  const goToPmDetail = (task: ITask) => {
    if (!isEmpty(task)) {
      const pmId = task.id;
      const masterProjectId = task?.project?.id;
      const subProjectId = task?.subProject?.id;
      const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${task.pmType}`;
      navigate(url);
    }
  };

  const onSubmit = async (values: FormDataEntryValue) => {
    try {
      if (state.loading) return;
      setState((prev: IWorkOrderInitialState) => ({ ...prev, loading: true }));
      let payload = createWorkOrderPayload(state, values, props);
      payload = removeNullUndefined(payload);
      let formData = new FormData();
      if (!isEmpty(state.fileList)) {
        formData.append(
          "image",
          state.fileList[state.fileList.length - 1]?.originFileObj as Blob,
        );
      }
      for (let key in payload) {
        if (key === "approvers") {
          // Check if approvers is an array or a single string
          if (Array.isArray(payload[key])) {
            payload[key].forEach((ap: any, index: any) => {
              formData.append(`approvers[${index}]`, ap.id || ap);
            });
          } else {
            // If it's a single string, treat it as a 0-indexed array item
            formData.append(`approvers[0]`, payload[key]);
          }
        } else if (key === "assignees") {
          // Check if assignees is an array or a single string
          if (Array.isArray(payload[key])) {
            payload[key].forEach((as: any, index: any) => {
              formData.append(`assignees[${index}]`, as.id || as);
            });
          } else {
            // If it's a single string, treat it as a 0-indexed array item
            formData.append(`assignees[0]`, payload[key]);
          }
        } else if (
          key === "teamMembers" &&
          !payload?.teamId &&
          !props.editing
        ) {
          payload[key].forEach((as: any, index: any) => {
            formData.append(`teamMembers[${index}]`, as);
          });
        } else if (key === "teamMembers" && payload?.teamId) {
          formData.delete("teamMembers");
        } else if (key === "teamMembers" && props.copying) {
          payload[key].forEach((as: any, index: any) => {
            formData.append(`teamMembers[${index}]`, as);
          });
        } else if (key === "areaIds") {
          payload[key].forEach((ap: any, index: any) => {
            formData.append(`areaIds[${index}]`, ap.package_id || ap.id);
          });
        } else if (key === "assetIds") {
          payload[key].forEach((ap: any, index: any) => {
            formData.append(`assetIds[${index}]`, ap.tag_id || ap.id);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }
      if (props.editing && !props.copying) {
        formData.delete("teamMembers");
        await updatePrevMaintenances(props.taskId || "", formData);
        props.callbackEffects &&
          props.callbackEffects(undefined, "unique_key", tableFilters);
        props.FormInstance.resetFields();
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
        });
      } else {
        let type: string = "";
        if (props.type === PM_TYPES.ALL) {
          if (state.taskCategory === PM_TYPES.TASK) {
            type = PM_TYPES.TASK;
          } else {
            type = PM_TYPES.PM_EXTERNAL;
          }
        } else {
          type =
            props.type === PM_TYPES.TASK ? PM_TYPES.TASK : PM_TYPES.PM_EXTERNAL;
        }
        const response = await createPrevMaintenances(type, formData);
        if (response.status) {
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
          });
          props.callbackEffects &&
            props.callbackEffects(undefined, "unique_key", tableFilters);
          notification.success({
            key: "task-notification",
            message:
              props.type === PM_TYPES.TASK ||
              state.taskCategory === PM_TYPES.TASK
                ? MESSAGES.WORK_ORDER.TASK_CREATED
                : state.taskCategory ===
                  MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
                ? MESSAGES.WORK_ORDER.PREVENTIVE_MAINTENANCE_CREATED
                : MESSAGES.WORK_ORDER.CORRECTIVE_MAINTENANCE_CREATED,
            icon: props.isTask ? <TasksIcon /> : <PmExternalIcon />,
            description: (
              <TextToLink
                text={"Go to Detail"}
                onClick={() => {
                  notification.destroy("task-notification");
                  goToPmDetail(response.data);
                }}
              />
            ),
            duration: 5,
          });
        }
      }
      setState((prev: IWorkOrderInitialState) => ({
        ...prev,
        loading: false,
      }));
    } catch (ex) {
      setState((prev: IWorkOrderInitialState) => ({
        ...prev,
        loading: false,
      }));
      console.log(ex);
    }
  };

  const getPMDetails = async (id: string) => {
    setState((prev: IWorkOrderInitialState) => {
      return { ...prev, loading: true };
    });
    try {
      const response =
        props.editType === WORK_ORDER_EDITING_TYPES.THIS_EVENT
          ? (await fetchExternalPMDetails(id)).data
          : (await fetchPMDetailsMaster(id)).data?.masterPreventiveMaintenance;

      if (!isEmpty(response)) {
        getMasterProjectUsers(response.project.id);
        getTeamsList(response.project?.id);
        const responseTeam = Array.isArray(response.team?.projectTeamMembers)
          ? response?.team?.projectTeamMembers.flatMap(
              (member: any) => member.user,
            )
          : [];
        const responseUsers =
          response?.teamMembers?.map((member: any) => member.user) ?? [];
        const allAssetsKeys = [...response.assets, ...response.areas];
        if (!isEmpty(allAssetsKeys)) {
          setState((prev: IWorkOrderInitialState) => ({
            ...prev,
            selectedRowKeys: allAssetsKeys.map(
              (d: any) => d.asset?.id || d.area?.id,
            ) as any,
          }));
        }
        setState((prev: IWorkOrderInitialState) => ({
          ...prev,
          loading: false,
          taskDetails: response,
          isRecurring: response.isRecurring,
          dayType: response?.dayType,
          selectedMasterProject: response?.project,
          selectedSubProject: response.subProject,
          selectedAssets:
            response.assets &&
            (response.assets.map((d: any) => d.asset) as any),
          selectedAssetPackage:
            response.areas && (response.areas.map((d: any) => d.area) as any),
          selectedTeamMembers: isEmpty(response?.teamMembers)
            ? (responseTeam as IUser[] | ITeams[])
            : (responseUsers as IUser[] | ITeams[]),
          selectedMembertype: isEmpty(response?.teamMembers)
            ? "teams"
            : "users",
          selectedProcedureLibrary: (props.editType ===
          WORK_ORDER_EDITING_TYPES.ALL_EVENTS
            ? response?.procedure
            : response?.procedure || null) as any,
          procedureLibraryId: ((props.copying
            ? response?.procedureLibraryId
            : null) || null) as any,
          taskCategory: response.taskCategory,
          selectedTeamId: !isEmpty(response.team) ? response.team.id : null,
        }));

        initializeWorkOrderForm(props, response);
      }
    } catch (ex) {
      setState({
        ...state,
        loading: false,
      });
      console.log(ex);
    }
  };
  useEffect(() => {
    if (state.selectedMasterProject) {
      getTeamsList(state.selectedMasterProject?.id);
      getMasterProjectUsers(state.selectedMasterProject.id);
    }
    if (props.taskId) {
      getPMDetails(props.taskId);
    }
    if (!props.editing && !props.copying) {
      props.FormInstance.setFieldValue(
        WORK_ORDER_FORM_FIELD_TYPES.TASK_CATEGORY,
        props.type === PM_TYPES.TASK ? PM_TYPES.TASK : null,
      );
      setState((prev: IWorkOrderInitialState) => ({
        ...prev,
        // selectedTeamMembers: [user],
        taskCategory: props.type === PM_TYPES.TASK ? PM_TYPES.TASK : null,
      }));
    }
    if (!isEmpty(activeMasterProject) && !props.copying && !props.editing) {
      props.FormInstance.setFieldValue(
        WORK_ORDER_FORM_FIELD_TYPES.MASTER_PROJECT,
        activeMasterProject.id,
      );
      setState((prev: IWorkOrderInitialState) => ({
        ...prev,
        selectedMasterProject: activeMasterProject,
        // selectedTeamMembers: [user],
      }));
    }
  }, [props.taskId]);

  useEffect(() => {
    ModalServiceInstance.updateModalProps(
      DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
      equipmentModalConfig,
    );
  }, [state.selectedRowKeys]);

  return (
    <Spin
      spinning={state.loading}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh", display: "flex" }} // Ensures full height and flexbox alignment
      >
        <Col span={24} className="alectify-wo-create-edit-container">
          <Form
            form={props.FormInstance}
            layout="vertical"
            onFinish={onSubmit}
            initialValues={{
              [WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY_TYPE]: "monthly",
              [WORK_ORDER_FORM_FIELD_TYPES.DAY_DATE]: "date",
              [WORK_ORDER_FORM_FIELD_TYPES.MASTER_PROJECT]:
                activeMasterProject && activeMasterProject.id,
            }}
          >
            <BasicInformation
              FormInstance={props.FormInstance}
              selectedMasterProject={state.selectedMasterProject}
              selectedSubProject={state.selectedSubProject}
              selectedRowKeys={state.selectedRowKeys}
              selectedAssetPackage={state.selectedAssetPackage}
              selectedAssets={state.selectedAssets}
              taskDetails={state.taskDetails}
              editing={props.editing}
              isTask={props.isTask}
              type={props.type}
              copying={props.copying || false}
              onSelectSubProject={onSubProjectSelect}
              equipmentModalHandler={equipmentModalHandler}
              onSelectMasterProject={onMasterProjectSelect}
              onSelectTaskCategory={onSelectTaskCategory}
              handleImageUpload={handleImageUpload}
            />
            <Divider className="alectify-wo-divider" />
            <TeamMemberSelection
              FormInstance={props.FormInstance}
              masterProjectId={state.selectedMasterProject?.id || ""}
              selectedMembertype={state.selectedMembertype || ""}
              onSelectTeamMember={onSelectTeamMember}
              onSelectMemberType={onSelectMemberType}
              selectedTeamMembers={state.selectedTeamMembers || []}
              taskDetails={state.taskDetails}
              copying={props.copying}
              editing={props.editing}
              projectTeams={state.projectTeams}
              projectTeamMembers={state.projectTeamMembers}
              onSelect={onSelectTeamMember}
              onCreateAssignee={createAssignee}
              onRemoveAssignee={removeAssignee}
              onDeselectTeamMember={onDeselectTeamMember}
            />
            <Divider className="alectify-wo-divider" />
            <WorkOrderSchedule
              isRecurring={state.isRecurring}
              onRecurringChange={onRecurringChange}
              FormInstance={props.FormInstance}
              taskDetails={state.taskDetails}
              editing={props.editing}
              editType={props.editType}
              taskCategory={state.taskCategory || ""}
              isTask={
                props.type === PM_TYPES.TASK ||
                state.taskCategory === PM_TYPES.TASK
              }
              copying={props.copying || false}
            />
            <Divider className="alectify-wo-divider" />
            <WorkOrderProcedureSelection
              onSelectProcedure={onSelectProcedure}
              procedure={state.selectedProcedureLibrary}
              isTask={props.isTask}
              projectId={state.selectedMasterProject?.id || ""}
            />
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default WorkOrderCreateEdit;
