import AlectifyText from "static/texts.json";

export const SERVICE_UNIQUE_ID = {
  UNIQUE_FETCH_GLOBAL_EQUIPMENTS: "unique-id-001",
  TOP_5_SERVICE_API: "unique-id-top5-002",
  UNIQUE_FETCH_PM_EXTERNAL: "unique-pm-external-id-001",
  UNIQUE_FETCH_TASKS: "unique-tasks-id-001",
};

export const SECURITY_BANNER_FLAG = "showSecurityBanner";

export const BUTTON_TEXTS = {
  ALL: "All Asset",
  PARENT_ASSET: "Parent Asset",
};

export const BUTTON_TYPES = {
  ALL: "all",
  PARENT_ASSET: "parentAsset",
  SUB_ASSET: "subAsset",
};

export const PROCEDURE_BUTTON_TYPES = {
  CARD_VIEW: "cardView",
  TABLE_VIEW: "tableView",
};

export const assetsFilterOptions = [
  { value: BUTTON_TYPES.ALL, label: AlectifyText.ALL_ASSET },
  { value: BUTTON_TYPES.PARENT_ASSET, label: AlectifyText.PARENT_ASSETS },
  { value: BUTTON_TYPES.SUB_ASSET, label: AlectifyText.SUB_ASSETS },
];

export const statusMap: Record<string, string> = {
  COMPLETED: "CLOSED",
  PENDING: "SCHEDULED",
  SKIPPED: "VOID",
};
