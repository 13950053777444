import { createActionAndReducers } from "redux/utils/reducer.helper";
import * as TableFilterEffects from "./affects";
import { reducerName } from "./TableFilter.constants";
import { ITableFilterActions } from "./TableFilter.interface";
import { tableFilterInitialState } from "./TableFilter.initialState";
export * from "./TableFilter.constants";
export * from "./TableFilter.interface";

const { actions, reducer } = createActionAndReducers<ITableFilterActions>(
  reducerName,
  tableFilterInitialState,
  TableFilterEffects,
);

export { actions, tableFilterInitialState, reducer };
