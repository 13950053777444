import { memo, useEffect, useState } from "react";
import { Col, DatePicker, Form, notification, Row, Typography } from "antd";
import AlectifySelect from "../select";
import AlectifyText from "static/texts.json";
import AlectifyButton from "../button";
import { useParams } from "react-router-dom";
import {
  getDRProjectDetails,
  getShiftReport,
  getShiftReportOnRange,
} from "services/data-round/data-round.service";
import {
  IDataRoundProject,
  IShiftSchedule,
} from "services/data-round/data-round.interface";
import "./DataRoundsReports.scss";
import { RangePickerProps } from "antd/es/date-picker";
import { DatePickerProps } from "antd/lib";
import { IDataRoundReports } from "./DataRoundsReports.interface";

const { RangePicker } = DatePicker;

const DataRoundReports = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState<IDataRoundProject | null>(null);
  const [formValues, setFormValues] = useState<IDataRoundReports>({
    shift_date: null,
    shift: null,
    range: null,
    start_date: null,
    end_date: null,
  });

  const [isLoading, setIsLoading] = useState({
    shift: false,
    range: false,
  });

  const [FormInstance] = Form.useForm();

  const handleValuesChange = (changedValues: any) => {
    setFormValues((prevState: IDataRoundReports) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const onChangeShiftDate: DatePickerProps["onChange"] = (date, dateString) => {
    setFormValues((prevState: IDataRoundReports) => ({
      ...prevState,
      shift_date: dateString,
    }));
  };

  const onShiftSelection = (value: string) => {
    setFormValues((prevState: IDataRoundReports) => ({
      ...prevState,
      shift: value,
    }));
  };

  const downloadShiftReport = async () => {
    try {
      setIsLoading((prevState) => ({
        ...prevState,
        shift: true,
      }));
      if (projectId) {
        const params = {
          shift_date: formValues?.shift_date || "-",
          shift: formValues.shift || "-",
        };
        const response = await getShiftReport(projectId, params);
        if (response?.message) {
          notification.info({
            message: response?.message,
          });
        }
        if (response?.data?.encoded && response?.data?.file_name) {
          const csvContent = atob(response.data.encoded);
          const blob = new Blob([csvContent], { type: "text/csv" });
          const link = document.createElement("a");

          link.href = URL.createObjectURL(blob);
          link.download = response.data.file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Failed to download report: Invalid response data");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        shift: false,
      }));
    }
  };

  const downloadShiftRangeReport = async () => {
    try {
      setIsLoading((prevState) => ({
        ...prevState,
        range: true,
      }));
      if (projectId) {
        const params = {
          start_date: formValues?.start_date || "-",
          end_date: formValues?.end_date || "-",
        };
        const response = await getShiftReportOnRange(projectId, params);
        if (response.message) {
          notification.info({
            message: response.message,
          });
        }
        if (response?.data?.encoded && response?.data?.file_name) {
          const csvContent = atob(response.data.encoded);
          const blob = new Blob([csvContent], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = response.data.file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        range: false,
      }));
    }
  };

  const onDateRangeSelect: RangePickerProps["onChange"] = (
    dates,
    dateStrings,
  ) => {
    setFormValues((prevState: IDataRoundReports) => ({
      ...prevState,
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    }));
  };

  const getProjectDetails = async () => {
    try {
      const response = await getDRProjectDetails(projectId || "");
      if (response.success) {
        setProject(response.data);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    if (projectId) {
      getProjectDetails();
    }
  }, [projectId]);

  return (
    <div className="report-shift-wise-container">
      <Form
        className="data-rounds-reports-form"
        name="data-rounds-reports-form"
        form={FormInstance}
        onValuesChange={handleValuesChange}
      >
        <Row
          gutter={16}
          align={"middle"}
          justify={"start"}
          className="data-report-container"
        >
          <Col span={8}>
            <Typography.Text>Generate report for one shift</Typography.Text>
          </Col>
          <Col span={3}>
            <Form.Item name="shift_date">
              <DatePicker
                className="date-picker-container"
                onChange={onChangeShiftDate}
                placeholder={`${AlectifyText.DATE}`}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="shift">
              <AlectifySelect
                name="shift"
                placeholder={`${AlectifyText.SHIFT}`}
                onChange={onShiftSelection}
                options={
                  project?.shift_schedules?.map((shift: IShiftSchedule) => ({
                    label: shift.name,
                    key: shift?.id,
                    value: shift.id,
                  })) || []
                }
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <div className="download-btn-data-rounds">
              <AlectifyButton
                loading={isLoading.shift}
                onClick={downloadShiftReport}
                text={"Download CSV"}
                type="primary"
                className="alectify-primary-btn"
                disabled={!(formValues.shift && formValues.shift_date)}
              />
            </div>
          </Col>
        </Row>
      </Form>

      <Form
        className="data-rounds-reports-form"
        name="data-rounds-reports-form"
        form={FormInstance}
      >
        <Row
          gutter={16}
          align={"middle"}
          justify={"start"}
          className="data-report-container"
        >
          <Col span={8}>
            <Typography.Text>Generate report for a date range</Typography.Text>
          </Col>
          <Col span={6}>
            <Form.Item name="range">
              <RangePicker
                className="round-range-picker"
                onChange={onDateRangeSelect}
              />
            </Form.Item>
          </Col>

          <Col span={10}>
            <div className="download-btn-data-rounds">
              <AlectifyButton
                loading={isLoading.range}
                onClick={downloadShiftRangeReport}
                text={"Download CSV"}
                type="primary"
                className="alectify-primary-btn"
                disabled={!(formValues.start_date && formValues.end_date)}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default memo(DataRoundReports);
