import React, { memo, useState, useEffect } from "react";
import dayjs from "dayjs";
import { Button, Col, Radio, Row, Space, Tooltip } from "antd";
import WeeklyCalendarTemplate from "./calendar-pdf/WeeklyCalendarTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import type { RadioChangeEvent } from "antd";
import { ICalendarToolbar } from "./Calendar.interface";
import MonthlyCalendarTemplate from "./calendar-pdf/MonthlyCalendarTemplate";
import { LeftArrowIcon } from "components/icons";
import RightArrowIcon from "components/icons/RightArrowIcon";
import { monthMap } from "constants/index";
import "./Calendar.scss";
import { useDispatch } from "react-redux";
import { setActiveCalendarType } from "redux/components/common/sources";

const CalendarToolbar: React.FC<ICalendarToolbar> = ({
  label,
  calendarEvents,
  startDateQ,
  endDateQ,
  calendarSwitch,
  selectedMonthStartDate,
  miniMonthlyCalendarMonthAndYear,
  onChangeCalendarNext,
  onChangeCalendarPrev,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hideLeftArrow, setHideLeftArrow] = useState<boolean>(false);
  const [rightLeftArrow, setHideRightArrow] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const calendarType = searchParams.get("calendarType");
  const dispatch = useDispatch();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const start = searchParams.get("start");
    const end = searchParams.get("end");
    const { year, month } = miniMonthlyCalendarMonthAndYear.current;

    const shouldHideLeftArrow = () => {
      if (
        calendarType === "week" &&
        start === "2018-12-30" &&
        end === "2019-01-05"
      ) {
        return true;
      }
      if (calendarType === "month" && year === 2019 && month === 0) {
        return true;
      }
      return false;
    };

    const shouldHideRightArrow = () => {
      if (
        calendarType === "week" &&
        start === "2028-12-31" &&
        end === "2029-01-06"
      ) {
        return true;
      }
      if (calendarType === "month" && year === 2028 && month === 11) {
        return true;
      }
      return false;
    };

    setHideLeftArrow(shouldHideLeftArrow());
    setHideRightArrow(shouldHideRightArrow());
  }, [location.search, miniMonthlyCalendarMonthAndYear, calendarType]);

  const calenderTypeOptions = [
    { label: "Week", value: 1 },
    { label: "Month", value: 2 },
  ];

  const onChangeCalendarType = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setActiveCalendarType(value));

    const { year, month } = miniMonthlyCalendarMonthAndYear.current;

    if (value === 1) {
      const startOfWeek = dayjs().year(year).month(month).startOf("week");
      const endOfWeek = dayjs().year(year).month(month).endOf("week");

      const searchParams = new URLSearchParams(location.search);
      searchParams.set("start", startOfWeek.format("YYYY-MM-DD"));
      searchParams.set("end", endOfWeek.format("YYYY-MM-DD"));
      searchParams.set("calendarType", "week");

      navigate(`?${searchParams.toString()}`, { replace: true });
    } else if (value === 2) {
      const firstDayOfMonth = dayjs().year(year).month(month).startOf("month");
      const firstVisibleDay = firstDayOfMonth.startOf("week");
      const lastDayOfMonth = dayjs().year(year).month(month).endOf("month");
      const lastVisibleDay = lastDayOfMonth.endOf("week");

      const searchParams = new URLSearchParams(location.search);
      searchParams.set("start", firstVisibleDay.format("YYYY-MM-DD"));
      searchParams.set("end", lastVisibleDay.format("YYYY-MM-DD"));
      searchParams.set("calendarType", "month");

      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  };

  const handleTodayClick = () => {
    const today = dayjs();
    const newMonth = today.month();
    const newYear = today.year();
    miniMonthlyCalendarMonthAndYear.current = {
      month: newMonth,
      year: newYear,
    };
    dispatch(setActiveCalendarType(1));

    const startOfWeek = today.startOf("week");
    const endOfWeek = today.endOf("week");

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("start", startOfWeek.format("YYYY-MM-DD"));
    searchParams.set("end", endOfWeek.format("YYYY-MM-DD"));
    searchParams.set("calendarType", "week");

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  let [monthLabel, yearLabel] = label.split(" ");

  if (monthMap[monthLabel]) {
    monthLabel = monthMap[monthLabel];
  }
  return (
    <Row className="alectify-calendar-toolbar">
      <Col span={12}>
        <Space>
          <div className="toolbar-title">
            <span>
              <strong>{monthLabel}</strong>{" "}
              <span className="ml-5">{yearLabel}</span>
            </span>
          </div>

          {!hideLeftArrow && (
            <Tooltip
              title={calendarSwitch === 2 ? "Previous month" : "Previous week"}
            >
              <span className="arrow-container " onClick={onChangeCalendarPrev}>
                <LeftArrowIcon height={"14"} />
              </span>
            </Tooltip>
          )}

          <Tooltip title={dayjs().format("DD MMM YYYY")}>
            <Button onClick={handleTodayClick}>
              <span className="font-size-12">Today</span>
            </Button>
          </Tooltip>

          {!rightLeftArrow && (
            <Tooltip title={calendarSwitch === 2 ? "Next month" : "Next week"}>
              <span className="arrow-container" onClick={onChangeCalendarNext}>
                <RightArrowIcon height={"14"} />
              </span>
            </Tooltip>
          )}
        </Space>
      </Col>

      <Col span={12}>
        <div className="d-flex justify-flex-end">
          <Space className="radio-btns">
            <div className="mr-15">
              <Radio.Group
                options={calenderTypeOptions}
                onChange={onChangeCalendarType}
                value={calendarSwitch}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            {calendarSwitch === 2 ? (
              <MonthlyCalendarTemplate
                startDate={selectedMonthStartDate?.format("YYYY-MM-DD")}
                endDate={selectedMonthStartDate
                  ?.clone()
                  .endOf("month")
                  .format("YYYY-MM-DD")}
                calendarEvents={calendarEvents}
                loader={false}
              />
            ) : (
              <WeeklyCalendarTemplate
                startDate={startDateQ}
                endDate={endDateQ}
                calendarEvents={calendarEvents}
                loader={false}
              />
            )}
          </Space>
        </div>
      </Col>
    </Row>
  );
};

export default memo(CalendarToolbar);
