import { Col, Row, Space, Tag, Tooltip, Typography } from "antd";
import { IShiftCardsProps } from "./DataRoundShiftTimeline.interface";
import { IDataRoundInsights } from "services/data-round/data-round.interface";
import { isEmpty } from "lodash";
import AvatarGroup from "components/shared/avatar-group";
import DataRoundStatusTag from "components/shared/tags/DataRoundStatusTag";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import RoundsDetailTable from "components/shared/rounds-detail-table/RoundsDetailTable";
import { useParams } from "react-router-dom";
import { BugTwoTone, FieldTimeOutlined } from "@ant-design/icons";
import "./DataRoundTimeline.scss";
import TextToLink from "components/shared/text-to-link";

const ShiftCards: React.FC<IShiftCardsProps> = (props: IShiftCardsProps) => {
  const { projectId } = useParams();
  const shift = (): IDataRoundInsights | undefined => {
    return props.insights?.find(
      (insight: IDataRoundInsights) =>
        insight.shift?.id === props.shiftSchedule?.id &&
        insight.shift_date === props.shiftDate,
    );
  };
  const formatTime = (timeString: string) => {
    // Split the time string by ':' and '.' to get hours, minutes, and seconds
    const [hours, minutes] = timeString.split(/[:.]/);

    // Return the formatted time
    return `${hours}:${minutes}`;
  };
  const currentShift = shift();

  const openRoundDetails = (roundId: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.ROUND_DETAIL_MODAL,
      title: "Round Detail",
      width: 1200,
      footer: null,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.ROUND_DETAIL_MODAL,
        });
      },
      children: (
        <RoundsDetailTable projectId={projectId || ""} roundId={roundId} />
      ),
    });
  };
  return (
    <div
      className={`shift-card ${
        isEmpty(currentShift) ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={() => {
        if (!isEmpty(currentShift)) {
          openRoundDetails(currentShift?.id || "");
        }
      }}
    >
      <Row justify={"space-between"}>
        <Col span={24}>
          <span className="d-flex align-items-center">
            <Typography.Text className="mr-5 mt-3">
              {props.shiftSchedule.name || "-"}
            </Typography.Text>
            <DataRoundStatusTag
              status={(currentShift && currentShift?.status) || 0}
            />
          </span>
        </Col>
        {/* <Col span={12} className="text-align-right">
          <DataRoundStatusTag
            status={(currentShift && currentShift?.status) || 0}
          />
        </Col> */}
      </Row>
      <Row justify={"space-between"} align={"middle"}>
        {!isEmpty(currentShift) ? (
          <>
            {currentShift?.user && (
              <Col span={6}>
                <Space>
                  <AvatarGroup
                    users={[currentShift.user]}
                    showImage
                    size="small"
                  />
                  {`${currentShift.user.first_name || ""} ${
                    currentShift.user.last_name || ""
                  }`.trim()}
                </Space>
              </Col>
            )}
            <Col span={5}>
              <>
                <BugTwoTone twoToneColor="#eb2f96" className="mr-2" />
                Issues
                <Tag color={"#F3F3F3"} className="steps-tag round-tag ml-5">
                  {currentShift?.issues_count || 0}
                </Tag>
              </>
            </Col>
            <Col span={9}>
              <div className="d-flex align-items-center">
                <span className="time-icon">
                  <FieldTimeOutlined className="font-size-17" />
                  <Tooltip title="Start time">
                    {(currentShift?.start_time &&
                      formatTime(currentShift?.start_time)) ||
                      "-"}
                  </Tooltip>
                </span>
                <span className="m-5">to</span>
                <Tooltip title="Completed at">
                  {(currentShift?.end_time &&
                    formatTime(currentShift?.end_time)) ||
                    "-"}
                </Tooltip>
              </div>
            </Col>
          </>
        ) : (
          ""
        )}
        {currentShift && (
          <Col span={4} className="text-align-right">
            <TextToLink
              className="text-to-link-options"
              text={"View Detail"}
              onClick={() => openRoundDetails(currentShift?.id || "")}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ShiftCards;
