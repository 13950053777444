import { memo, useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Form,
  Row,
  Space,
  Tooltip,
  Upload,
  message,
} from "antd";
import {
  DeleteOutlined,
  CameraOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SelectMasterProject from "components/shared/master-project-select";
import AlectifySelect from "components/shared/select";
import { MESSAGES } from "constants/messages";
import AlectifyInput from "components/shared/input";
import AlectifyText from "static/texts.json";
import { WORK_ORDER_FORM_FIELD_TYPES } from "../WorkOrderCreateEdit.constants";
import TextEditor from "components/text-editor/TextEditor";
import AlectifyButton from "components/shared/button";
import { enumToTile } from "utils/helpers";
import {
  IWorkOrderBasicInfoCreationProps,
  WorkOrderTypeEnum,
} from "../WorkOrderCreateEdit.interface";
import SelectSubProject from "components/shared/sub-project-select/SelectSubProject";
import { getSelectedAssetsText } from "../WorkOrderCreateEdit.helpers";
import { isEmpty } from "lodash";
import { PM_TYPES } from "redux/components/pm-external";
import { CheckboxProps } from "antd/lib";

// Image upload preview function
const getBase64 = (file: Blob, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(file);
};

const BasicInformation: React.FC<IWorkOrderBasicInfoCreationProps> = (
  props: IWorkOrderBasicInfoCreationProps,
) => {
  const [previewImages, setPreviewImages] = useState<string[]>(
    (props.taskDetails?.imageUrl && [props.taskDetails?.imageUrl]) || [],
  ); // Multiple images
  const [priority, setPriority] = useState<string>(
    props.taskDetails?.priority || "NORMAL",
  );

  const handleChange = (info: any) => {
    const file = info.file.originFileObj || info.file;
    getBase64(file, (url) => {
      props.handleImageUpload(info.fileList);
      setPreviewImages([url]); // Add image to the list
    });
  };

  const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage ? false : Upload.LIST_IGNORE;
  };

  const handleRemove = (index: number) => {
    // const updatedImages = [...previewImages];
    // updatedImages.splice(index, 1); // Remove the image from the list
    setPreviewImages([]);
    props.handleImageUpload([]);
  };

  const getTaskCategoryOptions = () => {
    return [
      {
        label: enumToTile(WorkOrderTypeEnum.PREVENTIVE_MAINTENANCE),
        key: enumToTile(WorkOrderTypeEnum.PREVENTIVE_MAINTENANCE),
        value: WorkOrderTypeEnum.PREVENTIVE_MAINTENANCE,
        disabled: props.type === PM_TYPES.TASK,
      },
      {
        label: enumToTile(WorkOrderTypeEnum.CORRECTIVE_MAINTENANCE),
        key: enumToTile(WorkOrderTypeEnum.CORRECTIVE_MAINTENANCE),
        value: WorkOrderTypeEnum.CORRECTIVE_MAINTENANCE,
        disabled: props.type === PM_TYPES.TASK,
      },
      {
        label: enumToTile(WorkOrderTypeEnum.TASK),
        key: enumToTile(WorkOrderTypeEnum.TASK),
        value: WorkOrderTypeEnum.TASK,
        disabled: props.type === PM_TYPES.PM_EXTERNAL,
      },
    ];
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      setPriority("CRITICAL");
    } else {
      setPriority("NORMAL");
    }
  };

  useEffect(() => {
    if (!isEmpty(props.taskDetails)) {
      setPriority(props.taskDetails?.priority as any);
    }
  }, [props.taskDetails]);

  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            1. Work Order Details
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">1/4</span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"} gutter={10}>
          <Col span={24}>
            <AlectifyInput
              name={WORK_ORDER_FORM_FIELD_TYPES.WORK_TITLE}
              placeholder={AlectifyText.WORK_TITLE}
              label={AlectifyText.WORK_TITLE}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.WORK_TITLE,
                  ),
                },
              ]}
              type="text"
            />
          </Col>
          <Col span={12}>
            <AlectifySelect
              name={WORK_ORDER_FORM_FIELD_TYPES.TASK_CATEGORY}
              placeholder={AlectifyText.WORK_ORDER_TYPE}
              label={AlectifyText.WORK_ORDER_TYPE}
              options={getTaskCategoryOptions()}
              onSelect={props.onSelectTaskCategory}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.WORK_ORDER_TYPE,
                  ),
                },
              ]}
              disabled={props.editing}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              name={WORK_ORDER_FORM_FIELD_TYPES.TASK_PRIORITY}
              label={AlectifyText.PRIORITY}
              valuePropName="checked"
            >
              <div
                className="wo-priority-box cursor-pointer"
                // onClick={() =>
                //   setPriority((value: string) =>
                //     value === "NORMAL" ? "CRITICAL" : "NORMAL",
                //   )
                // }
              >
                <div>Critical</div>
                <div>
                  <Checkbox
                    checked={priority === "CRITICAL"}
                    onChange={onChange}
                    disabled={props.editing}
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
          {/* Image Upload Section */}
          <Col span={24} className="wo-image-upload-container">
            <Col span={24} className="wo-image-upload-container">
              <Upload
                className="full-width-upload" // Add custom class for full width
                listType="picture-card"
                showUploadList={false}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                accept="image/*"
              >
                <Space>
                  <CameraOutlined />
                  <span>Upload Photos</span>
                </Space>
              </Upload>
            </Col>
          </Col>
          {/* Preview Section */}
          <Col span={24}>
            <div className="image-preview-container">
              {previewImages.map((image, index) => (
                <div key={index} className="image-preview">
                  <img
                    src={image}
                    alt={`uploaded-${index}`}
                    className="preview-image"
                  />
                  <DeleteOutlined
                    className="delete-icon"
                    onClick={() => handleRemove(index)}
                  />
                </div>
              ))}
            </div>
          </Col>
          <Col span={24}>
            <Row justify={"center"} align={"middle"} gutter={[16, 16]}>
              <Col span={12}>
                <SelectMasterProject
                  onChange={() => {}}
                  onSelect={props.onSelectMasterProject}
                  defaultValue={props.selectedMasterProject?.id}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.SITE,
                      ),
                    },
                  ]}
                  disabled={props.editing}
                />
              </Col>
              <Col span={12}>
                <SelectSubProject
                  onSelect={props.onSelectSubProject}
                  masterProjectId={props.selectedMasterProject?.id || ""}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.ASSET_CATEGORY,
                      ),
                    },
                  ]}
                  disabled={props.editing}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              name={WORK_ORDER_FORM_FIELD_TYPES.ASSETS}
              label={AlectifyText.ASSETS}
            >
              <Tooltip
                className="alectify-selected-asset-tooltip"
                title={
                  isEmpty(props.selectedAssets) ||
                  isEmpty(props.selectedAssetPackage) ? null : (
                    <ol className="selected-documents-list">
                      {[
                        ...(props.selectedAssets as any),
                        ...(props.selectedAssetPackage as any),
                      ].map((equipment: any) => (
                        <li>
                          {equipment?.name ||
                            equipment.tag_name ||
                            equipment.name ||
                            equipment.package_name}
                        </li>
                      ))}
                    </ol>
                  )
                }
              >
                <AlectifyButton
                  name="Assets"
                  icon={
                    (isEmpty(props.selectedAssetPackage) &&
                      isEmpty(props.selectedAssets) && <PlusOutlined />) || (
                      <></>
                    )
                  }
                  text={
                    getSelectedAssetsText(
                      props.selectedAssets,
                      props.selectedAssetPackage,
                    ) || AlectifyText.ADD_ASSETS
                  }
                  type="primary"
                  className="full-width wo-assets-btn"
                  onClick={props.equipmentModalHandler}
                  disabled={isEmpty(props.selectedSubProject) || props.editing}
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={WORK_ORDER_FORM_FIELD_TYPES.DETAILS}
              label={AlectifyText.DESCRIPTION}
              className="m-0"
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.DESCRIPTION,
                  ),
                },
              ]}
            >
              <TextEditor
                name="description"
                initialValue={props.taskDetails?.detail || ""}
                onChange={(value) => {
                  props.FormInstance.setFieldValue(
                    WORK_ORDER_FORM_FIELD_TYPES.DETAILS,
                    value,
                  );
                }}
                customClass="wo-description m-0"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(BasicInformation);
