import { Col, DatePicker, Form, Row } from "antd";
import { IIncidentCommentBoxProps } from "./IncidentDetails.interface";
import { IR_FIELD_NAMES } from "../incident-report-creation/IncidentReportCreateEdit.constants";
import AlectifyText from "static/texts.json";
import dayjs from "dayjs";
import TextEditor from "components/text-editor/TextEditor";
import { MESSAGES } from "constants/messages";
import AlectifyInput from "components/shared/input";
import AlectifyButton from "components/shared/button";
import { useState } from "react";
import { createNewIncidentComment } from "services/incident-reports/incident-reports.service";

const IncidentCommentBox: React.FC<IIncidentCommentBoxProps> = (
  props: IIncidentCommentBoxProps,
) => {
  const [loader, setLoader] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    setLoader(true);
    try {
      const payload = {
        ...values,
        callAt: dayjs(values.callAt).format(),
        nextUpdateAt: dayjs(values.nextUpdateAt).format(),
      };
      console.log(payload);
      const response = await createNewIncidentComment(
        props.incidentId,
        payload,
      );
      if (response.status) {
        props.FormInstance.resetFields();
        props.callback && props.callback();
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };
  return (
    <Form layout="vertical" onFinish={onSubmit} form={props.FormInstance}>
      {/*  <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            Comments & Emails ({props.commentsCount})
          </span>
        </Col>
      </Row> */}
      <div className="alectify-incident-section-card">
        <Row justify={"center"} align={"top"} gutter={10}>
          <Col span={12}>
            <Row justify={"start"} align={"top"} gutter={10}>
              <Col span={12}>
                <Form.Item
                  name={IR_FIELD_NAMES.TIME_OF_CALL}
                  label={"Time of Call (24 hrs)"}
                  className="mb-5"
                >
                  <DatePicker
                    showTime={
                      {
                        format: "HH:mm",
                        defaultValue: dayjs("00:00", "HH:mm"),
                      } as any
                    }
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={IR_FIELD_NAMES.NEXT_UPDATE}
                  label={"Next Update (24 hrs)"}
                  className="mb-5"
                >
                  <DatePicker
                    showTime={
                      {
                        format: "HH:mm",
                        defaultValue: dayjs("00:00", "HH:mm"),
                      } as any
                    }
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <AlectifyInput
                  name={IR_FIELD_NAMES.SYSTEM_STATE}
                  label={"System State"}
                  type="text"
                  className="mb-5"
                  placeholder=""
                />
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Actions"}
                  name={IR_FIELD_NAMES.ACTIONS}
                  className="m-0 mb-0"
                >
                  <TextEditor
                    name={IR_FIELD_NAMES.ACTIONS}
                    initialValue={""}
                    onChange={(value) => {
                      props.FormInstance.setFieldValue(
                        IR_FIELD_NAMES.ACTIONS,
                        value,
                      );
                    }}
                    customClass="wo-description m-0"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Form.Item
              name={IR_FIELD_NAMES.DESCRIPTION}
              label={AlectifyText.DESCRIPTION}
              className="m-0 incident-detail-reply-description"
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.DESCRIPTION,
                  ),
                },
              ]}
            >
              <TextEditor
                name="description"
                initialValue={""}
                onChange={(value) => {
                  props.FormInstance.setFieldValue(
                    IR_FIELD_NAMES.DESCRIPTION,
                    value,
                  );
                }}
                customClass="wo-description incident-reply-description m-0"
              />
            </Form.Item>
          </Col>
          <Col span={24} className="text-align-right">
            <AlectifyButton
              name="pme-detail-action-btn"
              text={"Reply"}
              onClick={props.FormInstance.submit}
              type="primary"
              loading={loader}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default IncidentCommentBox;
