import { PauseCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
// import { Space, Tag } from "antd";
import { CrossRedIcon, WaitingIcon } from "components/icons";
import CheckIcon from "components/icons/CheckIcon";
import { ReactNode } from "react";
import { RoundStatusEnum } from "services/data-round/data-round.interface";
import "./AlectifyTags.scss";

const DataRoundStatusTag: React.FC<{ status: RoundStatusEnum }> = ({
  status,
}) => {
  const statusTagMap: {
    [key in RoundStatusEnum]: { label: string; color: string; icon: ReactNode };
  } = {
    [RoundStatusEnum.NOT_STARTED]: {
      label: "Not Started",
      color: "#999999",
      icon: <CrossRedIcon fill="#fa7a7d" />,
    },
    [RoundStatusEnum.ON_GOING]: {
      label: "In Progress",
      color: "#25a0fd",
      icon: <WaitingIcon fill={"#25a0fd"} height="13" />,
    },
    [RoundStatusEnum.ENDED]: {
      label: "Completed",
      color: "#42be19",
      icon: <CheckIcon fill={"#42be19"} height={15} className="custom-class" />,
    },
    [RoundStatusEnum.PAUSED]: {
      label: "On Hold",
      color: "#ffb310",
      icon: <PauseCircleTwoTone twoToneColor="#ffb310" />,
    },
  };

  return (
    <Tooltip title={statusTagMap[status].label}>
      <span className="d-flex align-items-center">
        {statusTagMap[status].icon}
      </span>
    </Tooltip>
  );
};

export default DataRoundStatusTag;
