import { Avatar, Col, Radio, Row, Select, Space } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import AvatarGroup from "components/shared/avatar-group";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { IUser } from "redux/components/Auth";
// import { IRootState } from "redux/rootReducer";
import { ITeams } from "services/teams/teams.interface";
import { isExternalUser } from "utils/helpers";
import { IIncidentTeamMembersProps } from "../IncidentReportCreateEdit.interface";
import ExternalTag from "components/shared/tags/ExternalTag";
import { isEmpty } from "lodash";
import AlectifyTable from "components/shared/table";

const { Option, OptGroup } = Select;

const IncidentTeamMembers: React.FC<IIncidentTeamMembersProps> = (
  props: IIncidentTeamMembersProps,
) => {
  const [selectedRadio, setSelectedRadio] = useState<string>("users");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedOptions([]);
    //   props.FormInstance.setFieldValue("assignees", undefined);
    //   props.FormInstance.setFieldValue("approvers", undefined);
    const value = e.target.value as string;
    setSelectedRadio(value);
    props.onSelectMemberType(value);
  };
  const handleSelectChange = (value: string[]) => {
    setSelectedOptions(value);
  };
  useEffect(() => {
    if (props.incidentDetails) {
      if (!isEmpty(props.incidentDetails.team)) {
        setSelectedOptions([props.incidentDetails.team.id]);
        setSelectedRadio("teams");
      } else if (!isEmpty(props.incidentDetails.teamMembers)) {
        setSelectedRadio("users");
        setSelectedOptions(
          props.incidentDetails.teamMembers?.map((d: any) => d.user.id) || [],
        );
      }
    }
  }, [props.incidentDetails]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [props.masterProjectId]);

  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            2. Incident Team Members
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">2/5</span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"}>
          <Col span={24}>
            <Row gutter={16}>
              <>
                {/* Team Radio */}
                <Col span={8}>
                  <Radio.Group
                    onChange={handleRadioChange}
                    disabled={props.editing}
                    value={selectedRadio}
                    className=" wo-priority-box"
                  >
                    <Radio value="teams">Pre-defined teams</Radio>
                  </Radio.Group>
                </Col>
                {/* Users Radio */}
                <Col span={4}>
                  <Radio.Group
                    onChange={handleRadioChange}
                    value={selectedRadio}
                    disabled={props.editing}
                    className="wo-priority-box"
                  >
                    <Radio value="users">Users</Radio>
                  </Radio.Group>
                </Col>
              </>
              <Col span={12}>
                <Select
                  mode={selectedRadio === "users" ? "multiple" : undefined}
                  style={{ width: "100%" }}
                  placeholder={`Select ${
                    selectedRadio === "teams" ? "One team" : "multiple Users"
                  }`}
                  optionFilterProp="children"
                  onChange={handleSelectChange}
                  value={selectedOptions}
                  defaultValue={(props.selectedTeamMembers as any) || []}
                  onDeselect={props.onDeselectTeamMember}
                  onSelect={(e) => props.onSelectTeamMember}
                  disabled={props.editing}
                  maxTagCount={2}
                  {...props}
                  className="wo-team-selection-dropdown"
                >
                  {selectedRadio === "teams" ? (
                    <>
                      {props.projectTeams?.map((team: ITeams) => (
                        <Option key={team.id} value={team.id} label={team.name}>
                          <Space
                            direction="horizontal"
                            className="d-flex width-100 justify-content-space-between team-selected"
                          >
                            <span>{team?.name}</span>
                            <AvatarGroup
                              users={team?.team_members}
                              size={"small"}
                              maxCount={4}
                            />
                          </Space>
                        </Option>
                      ))}
                    </>
                  ) : (
                    <OptGroup label="Select Multiple Users">
                      {props.projectTeamMembers?.map((user: IUser) => (
                        <Option
                          key={user.id}
                          value={user.id}
                          label={`${user.first_name} ${user.last_name}`}
                        >
                          <Space direction="horizontal">
                            <Avatar
                              size={"small"}
                              src={user.image || NO_IMAGE_AVATAR}
                            />
                            <span>{user.first_name}</span>
                            <span>
                              {user.last_name}{" "}
                              {isExternalUser(user) ? "(External)" : <></>}
                            </span>
                          </Space>
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify={"center"}
          align={"middle"}
          gutter={[16, 16]}
          className="mt-10 alectify-work-order-teammember-selection"
        >
          <Col span={24}>
            <AlectifyTable
              showPagination={false}
              bordered={false}
              size="small"
              columns={[
                {
                  title: "Full Name",
                  dataIndex: "name",
                  render: (value, record: IUser) => (
                    <Space>
                      <AvatarGroup users={[record]} showImage />
                      <span>
                        {record.first_name} {record.last_name}
                      </span>
                      {isExternalUser(record) ? "(External)" : ""}
                    </Space>
                  ),
                },
                {
                  title: "Email",
                  dataIndex: "email",
                },
                {
                  title: "Phone",
                  dataIndex: "contactNumber",
                  render: (value) => <span>{value || "-"}</span>,
                },
              ]}
              dataSource={props.selectedTeamMembers || []}
              total={0}
              onDataFetch={() => {}}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default IncidentTeamMembers;
