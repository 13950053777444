import { Col, Row, Space, Upload, List, Typography } from "antd";
import { IIncidentAttachFilesProps } from "../IncidentReportCreateEdit.interface";
import AttachmentIcon from "components/icons/AttachmentIcon";
import { useState } from "react";
import DeleteIcon from "components/icons/DeleteIcon";
import PreviewFile from "components/shared/preview-file";

const IncidentAttachFiles: React.FC<IIncidentAttachFilesProps> = (
  props: IIncidentAttachFilesProps,
) => {
  const [fileList, setFileList] = useState<File[]>([]); // Store selected files

  const handleChange = (info: any) => {
    const file = info.file.originFileObj || info.file;
    if (
      file &&
      !fileList.some((existingFile) => existingFile.name === file.name)
    ) {
      // Add file only if it does not already exist in the fileList
      setFileList((prevList) => {
        props.setFileList([...prevList, file]);
        return [...prevList, file];
      });
    }
  };

  const handleRemove = (fileToRemove: File) => {
    setFileList((prevList) =>
      prevList.filter((file) => file.name !== fileToRemove.name),
    ); // Remove file from the list
  };

  return (
    <>
      <Row justify={"space-between"} align={"middle"} gutter={[16, 16]}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            5. File Attachments
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">
            {fileList.length}/5
          </span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"} gutter={10}>
          <Col span={24} className="wo-image-upload-container">
            <Upload
              className="full-width-upload"
              listType="picture-card"
              multiple
              showUploadList={false}
              onChange={handleChange}
              customRequest={() => {
                // This prevents the Upload component from making API calls
                return;
              }}
              accept="*"
            >
              <Space>
                <AttachmentIcon color="#0954f1" />
                <span>Attach File(s)</span>
              </Space>
            </Upload>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {fileList.length > 0 && (
              <>
                <Typography.Title level={5} className="mt-15">
                  Selected Files
                </Typography.Title>
                <List
                  bordered
                  dataSource={fileList}
                  renderItem={(file) => (
                    <List.Item
                      actions={[
                        <span
                          key="remove"
                          onClick={() => handleRemove(file)}
                          style={{ color: "#ff4d4f", cursor: "pointer" }}
                        >
                          <DeleteIcon />
                        </span>,
                      ]}
                    >
                      <span>{file.name}</span>
                    </List.Item>
                  )}
                />
              </>
            )}
            {props.editing && (
              <>
                <Typography.Title level={5} className="mt-15">
                  Uploaded Files
                </Typography.Title>
                <List
                  bordered
                  dataSource={props.incidentDocuments || []}
                  renderItem={(file: any) => (
                    <List.Item>
                      <PreviewFile
                        fileName={file?.fileName}
                        fileUrl={file?.filePath}
                        isActivevalue={file?.isActive}
                      />
                    </List.Item>
                  )}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IncidentAttachFiles;
