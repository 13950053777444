import { get } from "lodash";
import { memo } from "react";
import { Popover } from "antd";
import { ICalendarEvent } from "services/calendar/calendar.interface";
import {
  CalendarEventStatusEnum,
  CalendarEventTypesEnum,
  PM_STATUS,
} from "enums";
import { CheckMarkSingleIcon } from "components/icons";
import TasksIcon from "components/icons/TasksIcon";
import PmExternalIcon from "components/icons/PmExternalIcon";
import PmInternalIcon from "components/icons/PmInternalIcon";
import { ROUTES } from "routes/Routes.constants";
import { useNavigate } from "react-router-dom";
import {
  ScheduledTag,
  SkippedTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DelayedTag from "components/shared/tags/DelayedTag";
import ClosedTag from "components/shared/tags/ClosedTag";
import InProgressTag from "components/shared/tags/InProgressTag";
import EventPopoverContent from "./EventPopoverContent";
import "./Calendar.scss";
// import DelayedIcon from "components/icons/DelayedIcon";
export const EVENT_ICONS = {
  [CalendarEventTypesEnum.TASKS]: <TasksIcon />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIcon />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIcon />,
};

export const CALENDAR_STATUS_COMPONENTS = {
  [PM_STATUS.PENDING]: <ScheduledTag />,
  [PM_STATUS.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [PM_STATUS.INPROGRESS]: <InProgressTag />,
};

export const EVENT_STATUS_CHIPS = (props: ICalendarEvent) => ({
  [CalendarEventStatusEnum.DELAYED]: <DelayedTag />,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: (
    // <ClosedTag text={`Closed on ${displayDateTime(props?.completedAt)}`} />
    <ClosedTag text={`Closed`} />
  ),
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <ClosedTag
      text="Closed"
      // text={`Closed on ${displayDateTime(props?.completedAt)}`}
      color={"#d92323"}
      fill={"#f94c4c"}
    />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <SkippedTag />,
});
export const EVENT_CLASSES: any = {
  [CalendarEventStatusEnum.DELAYED]: "delayed",
  [CalendarEventStatusEnum.PENDING]: "pending",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "completed",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "completed",
  [CalendarEventStatusEnum.SKIPPED]: "skipped",
};

export const EVENT_Text: any = {
  [CalendarEventStatusEnum.DELAYED]: "Over Due",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "Completed(Due Date Passed)",
};

const CalendarEvent: React.FC<ICalendarEvent> = (props: ICalendarEvent) => {
  const masterProject = get(props, "master_project[0]");
  const navigate = useNavigate();
  const goToPmDetailPage = (props: any) => {
    const masterProjectId = get(props, "master_project[0].id");
    const subProjectId = get(props, "sub_project[0].id");
    const pmId = props.id;
    const pmType = props.type;
    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${pmType}`;
    navigate(url);
  };

  const assetName =
    (Array.isArray(props.asset) && props.asset.length > 1) ||
    (Array.isArray(props.asset_package) && props.asset_package.length > 1) ||
    (Array.isArray(props.asset) &&
      props.asset.length === 1 &&
      Array.isArray(props.asset_package) &&
      props.asset_package.length === 1)
      ? "Multiple Assets"
      : Array.isArray(props.asset) && props.asset.length === 1
      ? get(props.asset, "[0].asset.name")
      : Array.isArray(props.asset_package) && props.asset_package.length === 1
      ? get(props.asset_package, "[0].area.name")
      : "No Asset";

  const additionalAssets = (() => {
    const additionalCount = props.asset.length + props.asset_package.length;
    return additionalCount > 1 ? `[${additionalCount}]` : "";
  })();

  return (
    <div className="event-container">
      <Popover
        content={<EventPopoverContent event={props} />}
        trigger="hover"
        placement="left"
        overlayClassName={"events-popover"}
      >
        <div
          className={`alectify-calendar-event ${
            EVENT_CLASSES[props.event_status as CalendarEventStatusEnum]
          }`}
          onClick={() => {
            goToPmDetailPage(props);
          }}
        >
          <div
            className={`event-content ${
              props?.event_status === CalendarEventStatusEnum.DELAYED ||
              props?.event_status === CalendarEventStatusEnum.DELAYED_COMPLETED
                ? "event-title-red-color"
                : "event-title-normal-color"
            } ${
              props?.event_status === CalendarEventStatusEnum.ON_TIME_COMPLETED
                ? "event-title-green-color"
                : "event-title-normal-color"
            }`}
            style={{
              background: masterProject?.color
                ? `rgba(${parseInt(
                    masterProject.color.slice(1, 3),
                    16,
                  )}, ${parseInt(
                    masterProject.color.slice(3, 5),
                    16,
                  )}, ${parseInt(masterProject.color.slice(5, 7), 16)}, 0.2)`
                : "transparent",
            }}
          >
            <div
              style={{
                padding: "0px 2px",
                marginRight: "3px",
                borderRadius: "10px",
                background: `${masterProject?.color || "transparent"}`,
              }}
            />
            <div className="text-ellipsis">
              {props?.event_status ===
              CalendarEventStatusEnum.ON_TIME_COMPLETED ? (
                <div className="calendar-check-mark calendar-check-mark-green">
                  <CheckMarkSingleIcon fill={"#fff"} background={"#0BB731"} />
                </div>
              ) : null}

              {props?.event_status ===
              CalendarEventStatusEnum.DELAYED_COMPLETED ? (
                <div className="calendar-check-mark calendar-check-mark-red">
                  <CheckMarkSingleIcon />
                </div>
              ) : null}

              <div className="event-title">
                <strong>{props?.title || "(No title)"}</strong>
              </div>

              <div className="event-name">
                <span className="asset-name-span">{assetName}</span>
                {additionalAssets && (
                  <div className="extra-asset">{additionalAssets}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default memo(CalendarEvent);
