export const GET_PM_INTERNAL_URL =
  "/preventive-maintenances/{masterProjectId}/{subProjectId}/{pmType}";
export const GET_PM_COUNTS =
  "/preventive-maintenances/{masterProjectId}/{subProjectId}/counts";
export const UPLOAD_PM_DOCUMENTS = "/preventive-maintenance-documents/{pmId}";
export const GET_PM_DOCUMENTS = "/preventive-maintenance-documents/{pmId}";
export const GET_PREVENTIVE_MAINTENANCE_DETAIL =
  "/preventive-maintenances/{pmId}";
export const GET_PREVENTIVE_MAINTENANCE_DETAIL_MASTER =
  "/preventive-maintenances/master/{pmId}";
export const GET_PM_BY_DUE_DATE =
  "/preventive-maintenances/all/all/{startDate}/{endDate}";
export const GET_WORK_ORDER_SUMMARY =
  "/preventive-maintenances/v2/{masterProjectId}/{subProjectId}/counts";
export const GET_CLOSED_WORK_ORDERS_BY_ASSIGNEES =
  "/preventive-maintenances/closed-work-orders-by-assignees";
export const GET_CLOSED_WORK_ORDERS =
  "/preventive-maintenances/closed-work-orders";
export const GET_MASTER_PM_DETAIL = "/master-preventive-maintenance";
export const GET_MASTER_PM_CSV = "/master-preventive-maintenance/download";
