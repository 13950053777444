import { Form, Spin, message } from "antd";
import DrStepNavigation from "./DrStepNavigation";
import {
  IDrProjectCreationProps,
  IDrProjectCreationState,
} from "./DrProjectCreation.interface";
import { useRef, useState, useEffect } from "react";
import CreateEditSubProjectFooter from "components/shared/create-edit-sub-project/CreateEditSubProjectFooter";
import DrProjectBasicInfo from "./DrProjectBasicInfo";
import {
  ICreateDRProjectPayload,
  IShiftSchedule,
} from "services/data-round/data-round.interface";
import {
  createDrProject,
  updateDrProject,
} from "services/data-round/data-round.service";
import ManageGroups from "components/shared/manage-groups/ManageGroups";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyDrawer from "components/drawer";
import { isEqual } from "lodash";

const DrProjectCreation: React.FC<IDrProjectCreationProps> = ({
  isEdit = false,
  projectId = "",
  masterProjectId = "",
  projectDetail,
  FormInstance,
  getDataRoundProject,
}: IDrProjectCreationProps) => {
  const [shiftCount, setShiftCount] = useState<number | null>(1);
  const [isProjectCreated, setIsProjectCreated] = useState<boolean>(false);

  const [state, setState] = useState<IDrProjectCreationState>({
    projectId: "",
    selectedValues: [1],
    currentActiveTab: 1,
    loading: false,
    project: null,
  });

  const shiftScheduleRef = useRef<IShiftSchedule[] | undefined>();
  const startTimeRef = useRef<string>("");

  const previousProjectData = useRef<any>({});
  const currentFormData = useRef<any>({});
  const initialEditingValues = useRef<any>({});

  useEffect(() => {
    if (isEdit) {
      initialEditingValues.current = {
        name: projectDetail?.name,
        shifts_per_day: projectDetail?.shifts_per_day,
        start_time_first_shift: projectDetail?.start_time_first_shift,
        team: projectDetail?.team,
      };
    }
    return () => setIsProjectCreated(false);
  }, [isEdit, projectDetail]);

  const handleProjectSave = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await FormInstance.validateFields();
      const formValues = FormInstance.getFieldsValue();

      currentFormData.current = {
        name: formValues?.name,
        shifts_per_day: formValues?.shifts_per_day,
        start_time_first_shift:
          formValues?.start_time_first_shift?.format("HH:mm:ss"),
        team: formValues?.team,
      };

      const payload: ICreateDRProjectPayload = {
        name: formValues.name,
        shift_schedules: shiftScheduleRef.current || [],
        master_project: masterProjectId,
        shifts_per_day: Number(formValues.shifts_per_day),
        start_time_first_shift:
          formValues?.start_time_first_shift?.format("HH:mm:ss"),
        team: formValues?.team,
      };

      if (isEdit) {
        if (
          formValues.shifts_per_day === state.project?.shifts_per_day &&
          formValues.start_time_first_shift.format("HH:mm:ss") ===
            state.project?.start_time_first_shift
        ) {
          delete payload.shift_schedules;
        }
        await handleUpdateProject(payload);
      } else {
        await handleCreateProject(payload);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateProject = async (payload: ICreateDRProjectPayload) => {
    const hasChanges = !isEqual(
      initialEditingValues.current,
      currentFormData.current,
    );
    if (!hasChanges) {
      navigateToNextTab();
      return;
    }

    const response = await updateDrProject(
      projectId || state.project?.id || "",
      payload,
    );
    if (response.success) {
      initialEditingValues.current = currentFormData.current;
      navigateToNextTab();
    }
  };

  const handleCreateProject = async (payload: ICreateDRProjectPayload) => {
    if (!isProjectCreated) {
      setIsProjectCreated(true);
      previousProjectData.current = { ...currentFormData.current };
      const response = await createDrProject(payload);

      if (response.success) {
        setState((prev) => ({
          ...prev,
          currentActiveTab: prev.currentActiveTab + 1,
          projectId: response.data.id,
          project: response.data,
        }));
      }
    } else if (!isEqual(previousProjectData.current, currentFormData.current)) {
      const response = await updateDrProject(
        projectId || state.project?.id || "",
        payload,
      );
      if (response.success) {
        previousProjectData.current = currentFormData.current;
        navigateToNextTab();
      }
    } else {
      navigateToNextTab();
    }
  };

  const handleSubmit = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const payload: ICreateDRProjectPayload = { is_draft: false };

      if (state.project?.is_draft) {
        const response = await updateDrProject(
          projectId || state.project?.id || "",
          payload,
        );
        if (response.success) {
          handleClose();
        }
      } else {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      message.success(
        `Project has been ${isEdit ? "Updated" : "Created"} successfully`,
      );
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleClose = () => {
    FormInstance.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CREATE_NEW_DR_PROJECT,
    });
    getDataRoundProject();
  };

  const navigateToNextTab = () => {
    setState((prev) => ({
      ...prev,
      currentActiveTab: prev.currentActiveTab + 1,
    }));
  };

  const navigateToPreviousTab = () => {
    setState((prev) => ({
      ...prev,
      currentActiveTab: prev.currentActiveTab - 1,
    }));
  };

  const renderStages = [
    <DrProjectBasicInfo
      state={state}
      setState={setState}
      masterProjectId={masterProjectId}
      shiftCount={shiftCount}
      setShiftCount={setShiftCount}
      FormInstance={FormInstance}
      shiftScheduleRef={shiftScheduleRef}
      startTimeRef={startTimeRef}
      projectId={projectId || ""}
      isEditing={isEdit}
      project={state.project}
    />,
    <ManageGroups
      isEditing={isEdit}
      projectId={state.projectId || projectId || ""}
      FormInstance={FormInstance}
    />,
  ];

  return (
    <Spin spinning={state.loading}>
      <div className="create-edit-data-rounds-container">
        <div className="create-edit-body">
          <div className="create-edit-data-rounds-navigation-container">
            <DrStepNavigation currentActiveTab={state.currentActiveTab} />
          </div>
          {renderStages[state.currentActiveTab - 1]}
        </div>
        <div className="create-edit-data-rounds-footer">
          <Form.Item>
            <CreateEditSubProjectFooter
              isEdit={isEdit}
              currentActiveTab={state.currentActiveTab}
              onCancel={
                state.currentActiveTab === 1
                  ? handleClose
                  : navigateToPreviousTab
              }
              onSubmit={
                state.currentActiveTab === 2 ? handleSubmit : handleProjectSave
              }
            />
          </Form.Item>
        </div>
      </div>
    </Spin>
  );
};

export default DrProjectCreation;
