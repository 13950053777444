import { Col, Collapse, Divider, Empty, Row, Skeleton, Space } from "antd";
import { IIncidentCommentProps } from "./IncidentDetails.interface";
import { useEffect, useState } from "react";
import { getAllIncidentComments } from "services/incident-reports/incident-reports.service";
import { IIncidentComment } from "services/incident-reports/incidnent-reports.interface";
import { useForm } from "antd/lib/form/Form";
import InfiniteScroll from "react-infinite-scroll-component";
import { IMetaNest } from "redux/interfaces";
import AvatarGroup from "components/shared/avatar-group";
import { displayDateTime } from "utils/helpers";
import IncidentCommentBox from "./IncidentCommentBox";
import { isEmpty } from "lodash";
import "../incident-report-creation/IncidentReportCreateEdit.scss";

const IncidentComments: React.FC<IIncidentCommentProps> = (
  props: IIncidentCommentProps,
) => {
  const [comments, setComments] = useState<IIncidentComment[] | null>(null);
  const [meta, setMeta] = useState<IMetaNest>({
    totalPages: 1,
    currentPage: 1,
    itemCount: 1,
    itemsPerPage: 10,
    totalItems: 1,
  });
  const [params, setParams] = useState({
    page: 1,
    limit: 5,
    ordering: "-created_at",
  });
  const [collapse, setCollapse] = useState<boolean>(false);

  const [FormInstance] = useForm();

  const getComments = async (id: string, reset = false) => {
    if (reset) setComments(null); // Reset state for new fetch
    try {
      const response = await getAllIncidentComments(
        id,
        reset ? { ...params, page: 1 } : params,
      );
      if (response.status) {
        setComments((prev) =>
          reset
            ? response.data
            : prev
            ? [...prev, ...response.data]
            : response.data,
        );
        setMeta(response.meta);
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  useEffect(() => {
    if (props.incidentId) {
      getComments(props.incidentId);
    }
  }, [props.incidentId, params]);

  return (
    <Row justify={"start"} align={"top"}>
      <Col
        span={24}
        className="alectify-wo-create-edit-container incident-comment-reply-container"
      >
        <Collapse
          onChange={(e: string | string[]) => setCollapse(!collapse)}
          items={[
            {
              key: "1",
              label: (
                <span className="alectify-work-order-section-card-heading">
                  Comments & Emails ({meta?.totalItems || 0})
                </span>
              ),
              children: (
                <IncidentCommentBox
                  FormInstance={FormInstance}
                  incidentId={props.incidentId || ""}
                  callback={() => {
                    getComments(props.incidentId || "", true);
                  }}
                  commentsCount={meta?.totalItems || 0}
                />
              ),
            },
          ]}
          defaultActiveKey={["1"]}
          // onChange={onChange}
        />
      </Col>
      <Col
        span={24}
        className="incident-detail-comment-list mt-10"
        id="scrollableDiv"
        style={{ height: !collapse ? "330px" : "700px", overflowY: "auto" }}
      >
        <InfiniteScroll
          dataLength={comments?.length || 0}
          next={() => {
            setParams((prev) => ({ ...prev, page: prev.page + 1 }));
          }}
          hasMore={(comments?.length || 0) < meta.totalItems}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget="scrollableDiv"
          endMessage={
            <p style={{ textAlign: "center", padding: "10px", color: "#888" }}>
              {!isEmpty(comments) ? (
                " You've reached the end of the comments."
              ) : (
                <Empty />
              )}
            </p>
          }
        >
          {comments?.map((comment) => (
            <>
              <Row
                justify={"start"}
                align={"top"}
                key={comment.id}
                className="mb-10"
              >
                <Col span={12} className="mb-10">
                  <Space>
                    <AvatarGroup
                      size={"large"}
                      users={comment?.createdBy ? [comment?.createdBy] : []}
                    />
                    <span>{`${comment?.createdBy?.first_name} ${comment?.createdBy?.last_name}`}</span>
                  </Space>
                </Col>
                <Col span={12} className="text-align-right">
                  {displayDateTime((comment?.createdAt as any) || "", true)}
                </Col>
                <Col
                  span={24}
                  className="incident-detail-comment-reply-content"
                >
                  <Space>
                    <span>
                      {" "}
                      <strong>Time of Call:</strong>
                    </span>
                    <span className="incident-statistic-card-helper-text">
                      {displayDateTime((comment?.callAt as any) || "", true)}
                    </span>
                    <span className="ml-20">
                      <strong>Next Update:</strong>
                    </span>
                    <span className="incident-statistic-card-helper-text">
                      {displayDateTime(
                        (comment?.nextUpdateAt as any) || "",
                        true,
                      )}
                    </span>
                  </Space>
                  <div className="mt-5">
                    <span>
                      <strong>Actions(s):</strong>
                    </span>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: comment?.actions || "",
                    }}
                  />
                  <span>
                    <Space>
                      <strong>System State:</strong>
                      <span>{comment?.systemState || "-"}</span>
                    </Space>
                  </span>

                  <div className="mt-5">
                    <span>
                      <strong>Description:</strong>
                    </span>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: comment?.description || "-",
                    }}
                  />
                </Col>
              </Row>
              <Divider className="mb-5" />
            </>
          ))}
        </InfiniteScroll>
      </Col>
    </Row>
  );
};

export default IncidentComments;
