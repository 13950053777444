import { AxiosResponse } from "axios";
import {
  IProjectSparePart,
  ISparePart,
  ISparePartDetailResponse,
  ISparePartDrawResponse,
  ISparePartResponse,
} from "redux/components/spare-parts/spare-parts.interface";
import { MULTIPART_HEADERS, pmHttp } from "utils/client";
import {
  GET_SPARE_PARTS,
  CREATE_SPARE_PART,
  GET_SPARE_PARTS_PROJECT_CATEGORIES,
  UPDATE_SPARE_PART,
  DELETE_SPARE_PART,
  DRAW_SPARE_PARTS,
  FIND_SPARE_PARTS,
  DRAW_SPARE_PARTS_HISTORY,
  GET_GLOBAL_SPARE_PARTS,
  GET_SPARE_PART_CATEGORIES,
  GET_SPARE_PART_VENDORS,
  REFILL_SPARE_PART,
  GET_SPARE_PART_DASHBOARD_STATS,
  GET_SPARE_PART_MONTHLY_COUNTS_HISTORY,
  GET_SPARE_PART_MONTHLY_COST_HISTORY,
  DOWNLOAD_SPARE_PARTS_AS_CSV,
  WORK_ORDER_SPARE_PARTS_HISTORY,
  CREATE_SPARE_PART_ORDER,
  GET_SPARE_PARTS_DETAIL,
  DELETE_SPARE_PART_ORDER,
  UPLOAD_SPARE_PARTS_IMAGE,
} from "./spare-parts.enpoints";
import { IParams } from "redux/interfaces";
import {
  IRefillSparePartPayload,
  ISparePartCategoryResponse,
  ISparePartDashboardMonthlyCost,
  ISparePartDashboardMonthlyCount,
  ISparePartDashboardStats,
  ISparePartDrawPayload,
} from "./spare-parts.interface";
import { ICreateSparePartPayload } from "components/shared/create-edit-spare-part/CreateEditSparePart.interface";
import { IOrganizationResponse } from "services/organization/organization.service";

/**
 * Fetches Spare Parts for all projects.
 *
 * @throws If an error occurs during the request.
 */
export const fetchSpareParts = async (params: IParams, isGlobal?: boolean) => {
  try {
    const response: AxiosResponse<ISparePartResponse> = await pmHttp.get(
      isGlobal ? GET_GLOBAL_SPARE_PARTS : GET_SPARE_PARTS,
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches spare parts categories associated with a master project.
 *
 * @param masterProjectId - The ID of the master project for which spare parts categories are requested.
 * @returns A promise that resolves with spare parts categories data.
 * @throws Throws an error if the request to fetch spare parts categories fails.
 */
export const fetchSparePartsCategories = async (
  masterProjectId: string,
): Promise<ISparePartCategoryResponse> => {
  try {
    const response: AxiosResponse<ISparePartCategoryResponse> =
      await pmHttp.get(
        GET_SPARE_PARTS_PROJECT_CATEGORIES.replace(
          "{masterProjectId}",
          masterProjectId,
        ),
      );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Creates a new spare part using the provided payload.
 *
 * @param {ICreateSparePartPayload} body - The payload containing information for creating the spare part.
 * @returns A promise that resolves with the created spare part.
 * @throws Throws an error if the request to create the spare part fails.
 */
export const createSparePart = async (
  body: ICreateSparePartPayload,
): Promise<{ data: ISparePart }> => {
  try {
    const response: AxiosResponse<{ data: ISparePart }> = await pmHttp.post(
      CREATE_SPARE_PART,
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Updates an existing spare part with the provided payload.
 *
 * @param sparePartId - The ID of the spare part to be updated.
 * @param body - The payload containing information for updating the spare part.
 * @returns A promise that resolves with the updated spare part data.
 * @throws Throws an error if the request to update the spare part fails.
 */
export const updateSparePart = async (
  sparePartId: string,
  body: ICreateSparePartPayload,
): Promise<{ data: ISparePart }> => {
  try {
    const response: AxiosResponse<{ data: ISparePart }> = await pmHttp.put(
      UPDATE_SPARE_PART.replace("{sparePartId}", sparePartId),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches spare parts associated with a specific part number.
 *
 * @param partNumber - The part number to search for.
 * @returns A promise that resolves with an array of spare parts matching the given part number.
 * @throws Throws an error if the request to fetch spare parts fails.
 */
export const fetchSparePartsByPartNumber = async (
  partNumber: string,
): Promise<{ data: IProjectSparePart[] }> => {
  try {
    const response: AxiosResponse<{ data: IProjectSparePart[] }> =
      await pmHttp.get(FIND_SPARE_PARTS.replace("{partNumber}", partNumber));
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Deletes existing spare parts with the provided payload.
 *
 * @param ids - The IDs of the spare parts to be deleted.
 * @returns A promise that resolves with the deleted spare part data.
 * @throws Throws an error if the request to update the spare part fails.
 */
export const deleteSpareParts = async (
  ids: string[],
): Promise<{ data: ISparePart }> => {
  try {
    const response: AxiosResponse<{ data: ISparePart }> = await pmHttp.delete(
      DELETE_SPARE_PART,
      { ids },
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Deletes existing spare parts with the provided payload.
 *
 * @param id - The ID of the spare part to be deleted.
 * @returns A promise that resolves with the deleted spare part data.
 * @throws Throws an error if the request to update the spare part fails.
 */
export const deleteOneSparePart = async (
  id: string,
): Promise<{ data: ISparePart }> => {
  try {
    const response: AxiosResponse<{ data: ISparePart }> = await pmHttp.delete(
      `${DELETE_SPARE_PART}/${id}`,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Deletes existing spare part order with the provided id.
 *
 * @param id - The ID of the spare part order to be deleted.
 * @returns A promise that resolves with the deleted spare part order data.
 * @throws Throws an error if the request to update the spare part fails.
 */
export const deleteSparePartOrder = async (
  id: string,
): Promise<{ data: ISparePart }> => {
  try {
    const response: AxiosResponse<{ data: ISparePart }> = await pmHttp.delete(
      DELETE_SPARE_PART_ORDER.replace("{orderId}", id),
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Draw Spare Part
 * @param {string} sparePartId - ID of the subproject.
 * @param {ISparePartDrawPayload} body - body of a patch request to draw spare part .
 * @returns {response} - Promise returns with data returned from the spare part draw api.
 * @throws If an error occurs during the request.
 */
export const drawSparePart = async (
  sparePartId: string,
  body: ISparePartDrawPayload,
): Promise<any> => {
  try {
    const response = await pmHttp.patch(
      DRAW_SPARE_PARTS.replace("{sparePartId}", sparePartId),
      body,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Refill/Restock Spare Part
 * @param {string} sparePartId - ID of the subproject.
 * @param {ISparePartDrawPayload} body - body of a patch request to refill spare part .
 * @returns {response} - Promise returns with data returned from the spare part refill api.
 * @throws If an error occurs during the request.
 */
export const refillSparePart = async (
  sparePartId: string,
  body: IRefillSparePartPayload,
): Promise<any> => {
  try {
    const response = await pmHttp.patch(
      REFILL_SPARE_PART.replace("{sparePartId}", sparePartId),
      body,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSparePartDashboardStats = async (
  params?: any,
): Promise<{ data: ISparePartDashboardStats }> => {
  try {
    const response = await pmHttp.get(GET_SPARE_PART_DASHBOARD_STATS, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSparePartDashboardMonthlyCounts = async (
  params?: any,
): Promise<{ data: ISparePartDashboardMonthlyCount[] }> => {
  try {
    const response = await pmHttp.get(
      GET_SPARE_PART_MONTHLY_COUNTS_HISTORY,
      params,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSparePartDashboardMonthlyCost = async (
  params?: any,
): Promise<{ data: ISparePartDashboardMonthlyCost[] }> => {
  try {
    const response = await pmHttp.get(
      GET_SPARE_PART_MONTHLY_COST_HISTORY,
      params,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches the draw history of a spare part from the server.
 *
 * @param sparePartId - The unique identifier of the spare part.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to an object containing the draw history data (ISparePartDraw[]).
 * @throws If an error occurs during the API request or processing the response.
 */
export const fetchSparePartsDrawHistory = async (
  sparePartId: string,
  params?: IParams,
): Promise<ISparePartDrawResponse> => {
  try {
    const response: AxiosResponse<ISparePartDrawResponse> = await pmHttp.get(
      DRAW_SPARE_PARTS_HISTORY.replace("{sparePartId}", sparePartId),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches spare parts categories.
 *
 * @returns A promise that resolves with spare parts categories data.
 * @throws Throws an error if the request to fetch spare parts categories fails.
 */
export const fetchSparePartAssociatedCategories = async (
  params: any,
): Promise<ISparePartCategoryResponse> => {
  try {
    const response: AxiosResponse<ISparePartCategoryResponse> =
      await pmHttp.get(GET_SPARE_PART_CATEGORIES, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches spare parts categories vendors
 *
 * @returns A Promise that resolves to the organization data.
 * @throws If an error occurs during the request.
 */
export const fetchSparePartAssociatedVendors = async (
  params: any,
): Promise<IOrganizationResponse> => {
  try {
    const response: AxiosResponse<IOrganizationResponse> = await pmHttp.get(
      GET_SPARE_PART_VENDORS,
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

export const downloadSparePartsAsCSV = async (params?: any) => {
  try {
    const response: AxiosResponse = await pmHttp.get(
      DOWNLOAD_SPARE_PARTS_AS_CSV,
      params,
      "",
      {},
      "blob",
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchWorkOrderSparePartsHisotry = async (
  workOrderId: string,
  params?: any,
) => {
  try {
    const response: AxiosResponse<ISparePartDrawResponse> = await pmHttp.get(
      WORK_ORDER_SPARE_PARTS_HISTORY.replace("{workOrderId}", workOrderId),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

export const createSparePartOrder = async (
  projectSparePartId: string,
  body?: any,
) => {
  try {
    const response: AxiosResponse = await pmHttp.post(
      CREATE_SPARE_PART_ORDER.replace(
        "{projectSparePartId}",
        projectSparePartId,
      ),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchSparePartsDetail = async (projectSparePartId: string) => {
  try {
    const response: AxiosResponse<ISparePartDetailResponse> = await pmHttp.get(
      GET_SPARE_PARTS_DETAIL.replace(
        "{projectSparePartId}",
        projectSparePartId,
      ),
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

export const uploadSparePartImage = async (id: string, body: any) => {
  try {
    const response: AxiosResponse<any> = await pmHttp.patch(
      UPLOAD_SPARE_PARTS_IMAGE.replace("{id}", id),
      body,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
