import { combineReducers } from "redux";
import * as Auth from "redux/components/Auth";
import * as Common from "redux/components/common";
import * as Tasks from "redux/components/tasks";
import * as Assets from "redux/components/assets";
import * as SubProject from "redux/components/sub-project";
import * as MasterProject from "redux/components/master-project";
import * as AssetPackages from "redux/components/asset-packages";
import * as PmInternal from "redux/components/pm-internal";
import * as Users from "redux/components/users";
import * as PmExternal from "redux/components/pm-external";
import * as Documents from "redux/components/documents";
import * as SpareParts from "redux/components/spare-parts";
import * as Procedures from "redux/components/procedures";
import * as Chatbot from "redux/components/chat-bot";
import * as Notifications from "redux/components/notifications";
import * as GlobalSearch from "redux/components/global-search";
import * as TableFilter from "redux/components/table-filters";

// add modules here after import to register reducers
const modules: any = [
  Auth,
  Common,
  Tasks,
  Assets,
  AssetPackages,
  SubProject,
  MasterProject,
  PmInternal,
  Users,
  Documents,
  PmExternal,
  SpareParts,
  Procedures,
  Chatbot,
  Notifications,
  GlobalSearch,
  TableFilter,
];

// Actions to reset state to default/initial state when logout.
const resetActions: Record<string, () => (dispatch: any) => void> = {
  Auth: Auth.actions.resetAuthState as any,
  Common: Common.actions.resetCommonState as any,
  Tasks: Tasks.actions.resetTaskState as any,
  Assets: Assets.actions.resetAssetState as any,
  SubProject: SubProject.actions.resetSubProjectState as any,
  MasterProject: MasterProject.actions.resetMasterProjectState as any,
  AssetPackages: AssetPackages.actions.resetAssetPackagesState as any,
  PmInternal: PmInternal.actions.resetPmInternalState as any,
  Users: Users.actions.resetUsersState as any,
  Documents: Documents.actions.resetDocumentState as any,
  PmExternal: PmExternal.actions.resetPmExternalState as any,
  SpareParts: SpareParts.actions.resetSparePartsState as any,
  Procedures: Procedures.actions.resetProcedureState as any,
  Chatbot: Chatbot.actions.resetChatbotState as any,
  Notifications: Notifications.actions.resetNotificatios as any,
  GlobalSearch: GlobalSearch.actions.resetGlobalSearchState as any,
  tableFilter: TableFilter.actions.resetTableFilterState as any,

  // Add reset actions for other reducers as needed
};

export const resetAllState = () => (dispatch: any) => {
  Object.keys(resetActions).forEach((reducerName) => {
    dispatch(resetActions[reducerName]());
  });
};

const rootReducer = combineReducers(
  modules.reduce((reducersObject: any, module: any) => {
    reducersObject[module.reducerName] = module.reducer;
    return reducersObject;
  }, {}),
);

// To expose rootReducer state type
export interface IRootState {
  [Auth.reducerName]: Auth.IAtuh;
  [Common.reducerName]: Common.ICommonState;
  [Tasks.reducerName]: Tasks.ITasksState;
  [Assets.reducerName]: Assets.IAssetsState;
  [SubProject.reducerName]: SubProject.ISubProjectResponse;
  [MasterProject.reducerName]: MasterProject.IMasterProjectInitialState;
  [AssetPackages.reducerName]: AssetPackages.IAssetPackageState;
  [PmInternal.reducerName]: PmInternal.IPmInternalState;
  [Users.reducerName]: Users.IUsersState;
  [Documents.reducerName]: Documents.IDocumentState;
  [PmExternal.reducerName]: PmExternal.IPmExternalInitialState;
  [SpareParts.reducerName]: SpareParts.ISparePartsInitialState;
  [Procedures.reducerName]: Procedures.IProceduresInitialState;
  [Chatbot.reducerName]: Chatbot.IChatbotInitialState;
  [Notifications.reducerName]: Notifications.INotificationsInitialState;
  [GlobalSearch.reducerName]: GlobalSearch.IGlobalSearchInitialState;
  [TableFilter.reducerName]: TableFilter.ITableFilterInitialState;
}

export default rootReducer;
